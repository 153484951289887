import { Modal } from "components/Common/Modal"
import { Button } from "components/Common/Button"
import { Input, Switch, TextInput } from "@mantine/core"
import { ImageUpload } from "components/Common/ImageUpload"
import { useEffect, useState } from "react"
import { useForm } from "@mantine/form"

type MediaType = {
  _id: string
  link: string
}

const CategoryEditModal = ({
  editModalVisible,
  data,
  onSave,
  isAddModal,
  onClose,
}: {
  data: any
  editModalVisible: any
  isAddModal: any
  onSave: any
  onClose: any
}) => {
  const categoryDetailsForm = useForm<{
    categoryName: string
    domain: string
    displayName: string
    visible: boolean
    media: MediaType | null
  }>({
    validateInputOnChange: true,
    initialValues: {
      domain: "",
      categoryName: "",
      displayName: "",
      visible: false,
      media: null,
    },
    validate: {
      displayName: (value) =>
        !value?.length ? "Display Name is required" : null,
    },
  })

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if (isAddModal) {
      onSave({
        categoryName: categoryDetailsForm.values.categoryName,
        domain: categoryDetailsForm.values.domain,
        displayName: categoryDetailsForm.values.displayName,
        visible: categoryDetailsForm.values.visible,
        media: categoryDetailsForm.values.media?._id,
      })
    } else {
      onSave({
        _id: data._id,
        displayName: categoryDetailsForm.values.displayName,
        visible: categoryDetailsForm.values.visible,
        media: categoryDetailsForm.values.media?._id,
      })
    }
  }

  const onCloseModal = () => {
    onClose()
    categoryDetailsForm.reset()
  }

  useEffect(() => {
    categoryDetailsForm.setValues({
      categoryName: data?.categoryName || "",
      domain: data?.domain || "",
      displayName: data?.displayName || "",
      visible: data?.visible || false,
      media: data?.image || null,
    })
  }, [data])

  return (
    <Modal
      opened={editModalVisible}
      onClose={onCloseModal}
      title={isAddModal ? "Add Category" : "Edit Category"}
    >
      <form className="pt-20 pl-20 pr-20 w-500">
        <div className="flex justify-center align-center flex-col">
          <ImageUpload
            mediaTypes={["image/png", "image/jpeg", "image/svg+xml"]}
            media={categoryDetailsForm.values.media?.link}
            setMedia={(img) => {
              categoryDetailsForm.setFieldValue("media", img[0])
            }}
          />
        </div>

        <TextInput
          label="ONDC ID (Domain)"
          classNames={{
            wrapper: "clr-grey txt-12 txtw-4 pb-10",
            label: "clr-grey txt-12 txtw-4",
          }}
          className="cursor-default"
          variant="filled"
          // value={data?.domain}
          readOnly={isAddModal ? false : true}
          {...categoryDetailsForm.getInputProps("domain")}
        />
        <TextInput
          label="CATEGORY NAME"
          classNames={{
            wrapper: "clr-grey txt-12 txtw-4 pb-10",
            label: "clr-grey txt-12 txtw-4",
          }}
          className="cursor-default"
          placeholder="Enter Category"
          variant="filled"
          readOnly={isAddModal ? false : true}
          // value={data?.categoryName}
          {...categoryDetailsForm.getInputProps("categoryName")}
        />

        <div className="w-full flex justify-stretch">
          <TextInput
            label="DISPLAY NAME"
            classNames={{
              label: "clr-grey txt-12 txtw-4 pb-10",
              wrapper: "clr-grey txt-12 txtw-4",
            }}
            placeholder="Enter Display Name"
            variant="filled"
            autoFocus={true}
            {...categoryDetailsForm.getInputProps("displayName")}
          />
        </div>
        <div className="w-full flex justify-stretch mt-10">
          <Input.Wrapper
            classNames={{
              label: "clr-grey txt-12 txtw-4",
            }}
            label="VISIBLE"
          >
            <Switch
              color="primary"
              classNames={{ track: "cursor-ptr" }}
              {...categoryDetailsForm.getInputProps("visible", {
                type: "checkbox",
              })}
            />
          </Input.Wrapper>
        </div>

        <div className="flex justify-end  mt-20 pt-10">
          <Button type="filled-primary" text="Save" onClick={onSubmit} />
        </div>
      </form>
    </Modal>
  )
}
export default CategoryEditModal
