import { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { useNavigate } from "react-router-dom"
import { useNotification } from "context"
import { uploadMedia } from "services/apis/admin"
import { checkAuth } from "utils/helper"
import { CloudUploadSVG as CloudUpload } from "assets/icons/index"
import "./Dropzone.scss"

const Dropzone = ({
  mediaTypes,
  media,
  setMedia,
  mediaValidationText,
  setMediaValidationText,
  mediaSize,
}: {
  mediaTypes: Array<string>
  media?: string
  setMedia: (media: any) => void
  mediaValidationText?: string
  setMediaValidationText?: (text: string) => void
  mediaSize?: number
}) => {
  const [currentMedia, setCurrentMedia] = useState("")
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({})
  const navigate = useNavigate()
  const { setText } = useNotification()

  useEffect(() => {
    if (media && typeof media === "string") {
      setCurrentMedia(media)
    }
  }, [media])

  useEffect(() => {
    ;(async () => {
      if (acceptedFiles.length > 0) {
        if (mediaTypes.indexOf(acceptedFiles[0].type) !== -1) {
          if (typeof setMediaValidationText !== "undefined") {
            setMediaValidationText("")
          }
          if (
            acceptedFiles[0].type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            setMedia(acceptedFiles[0])
          } else {
            if (mediaSize && setMediaValidationText) {
              if (acceptedFiles[0].size > mediaSize) {
                setMediaValidationText(
                  `Media should be under ${mediaSize / 1000}KB`
                )
              } else {
                setMediaValidationText("")
                checkAuth(
                  async () => {
                    const response = await uploadMedia(acceptedFiles[0])
                    if (response.success) {
                      setMedia(response.data)
                      setCurrentMedia(response.data)
                    }
                  },
                  setText,
                  navigate
                )
              }
            } else {
              checkAuth(
                async () => {
                  const response = await uploadMedia(acceptedFiles[0])
                  if (response.success) {
                    setMedia(response.data)
                    setCurrentMedia(response.data)
                  }
                },
                setText,
                navigate
              )
            }
          }
        } else {
          if (typeof setMediaValidationText !== "undefined") {
            setMediaValidationText("Invalid file format")
          }
          setCurrentMedia("")
        }
      }
    })()
  }, [acceptedFiles])

  function getMediaPreview() {
    if (
      mediaTypes.indexOf("image/png") !== -1 ||
      mediaTypes.indexOf("image/jpeg") !== -1
    ) {
      return (
        <img
          src={currentMedia}
          alt=""
          style={{
            width: "150px",
            height: "auto",
          }}
          className="bg-gray img-contain brd-10"
        />
      )
    } else if (mediaTypes.indexOf("video/mp4") !== -1) {
      return (
        <video width="150px" height="auto" controls>
          <source src={currentMedia} type="video/mp4" />
        </video>
      )
    }
    return <></>
  }

  return (
    <div
      {...getRootProps({
        className: "mb-20 w-max cursor-ptr",
      })}
    >
      <input className="input-zone" {...getInputProps()} />
      <div
        className={`${
          mediaValidationText && "dropzone-error"
        } dropzone-txt flex flex-col align-center pt-40 pr-40 pb-40 pl-40 brd-10`}
      >
        {currentMedia === "" ? <CloudUpload /> : getMediaPreview()}
        <p className="clr-grey txt-12">
          Drag &amp; Drop{" "}
          {mediaTypes[0] ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ? "File"
            : "Image"}{" "}
          Or
          <br />
          <span className="clr-primary">Browse</span> from Computer
        </p>
      </div>
      {mediaValidationText && (
        <p className="txt-12 clr-red mt-5">{mediaValidationText}</p>
      )}
    </div>
  )
}

export default Dropzone
