export const setStorageItem = (key: string, value: string) => {
  sessionStorage.setItem(key, JSON.stringify(value))
}

export const removeStorageItem = (key: string) => {
  sessionStorage.removeItem(key)
}

export const getStorageItem = (key: string) =>
  sessionStorage.getItem(key)
    ? JSON.parse(sessionStorage.getItem(key) ?? "")
    : null

export const emptyStorage = () => {
  sessionStorage.clear()
}
