import { Modal } from "components/Common/Modal"
import BasicDetails from "./BasicDetails"

const AddOfferModal = ({
  isAddOfferModalVisible,
  setAddOfferModalVisible,
  editOfferModal,
  setEditOfferModal,
}: {
  isAddOfferModalVisible: boolean
  setAddOfferModalVisible: (isAddOfferModalVisible: boolean) => void
  editOfferModal: any
  setEditOfferModal: Function
}) => {
  return (
    <Modal
      opened={isAddOfferModalVisible || editOfferModal.isOpen}
      onClose={() => {
        if (isAddOfferModalVisible) {
          setAddOfferModalVisible(false)
        } else {
          setEditOfferModal({
            isOpen: false,
            offer: "",
          })
        }
      }}
      title="add an offer"
    >
      <BasicDetails
        editOfferModal={editOfferModal}
        setEditOfferModal={setEditOfferModal}
        setAddOfferModalVisible={setAddOfferModalVisible}
      />
    </Modal>
  )
}

export default AddOfferModal
