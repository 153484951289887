import { createColumnHelper } from "@tanstack/react-table"
import RoleStatusBadge from "components/Common/Badge/RoleStatus"
import { Link } from "react-router-dom"
import {
  ECommerceOrdersTableFields,
  MobilityOrdersTableFields,
} from "types/order"
import { capitalize, padDecimals } from "utils/helper"
import styles from "../../pages/OrderManagement/OrderManagement.module.scss"

type OrderDetailsType = ECommerceOrdersTableFields | MobilityOrdersTableFields

const columnHelper = createColumnHelper<OrderDetailsType>()

export const ecommerceColumns = [
  columnHelper.accessor("customerOrderId", {
    header: "Order Id",
    enableSorting: false,
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("sellerName", {
    header: "Seller",
    enableSorting: false,
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("status", {
    header: "Order Status",
    enableSorting: false,
    cell: (info: any) =>
      info.getValue() ? (
        <RoleStatusBadge type={capitalize(info.getValue().toLowerCase())} />
      ) : null,
  }),
  columnHelper.accessor("totalProducts", {
    header: "Total Products",
    enableSorting: false,
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("products", {
    header: "Products",
    enableSorting: false,
    cell: (info: any) => {
      return (
        <div className={styles.ellipsis}>
          {info
            .getValue()
            .map((item: any) => item)
            .join(", ")}
        </div>
      )
    },
  }),
  columnHelper.accessor("categories", {
    header: "Sub Category",
    enableSorting: false,
    cell: (info: any) => (
      <div className={styles.ellipsis}>
        {info
          .getValue()
          ?.map((item: any) => item)
          ?.join(", ")}
      </div>
    ),
  }),
  columnHelper.accessor("totalAmount", {
    header: "Total Amount",
    enableSorting: false,
    cell: (info: any) => `₹${padDecimals(info.getValue().value)}`,
  }),
  columnHelper.accessor("_id", {
    header: "Action",
    enableSorting: false,
    cell: (info: any) => (
      <Link
        to={`/orders/${info.row.original._id}`}
        className="txt-12 clr-primary no-underline"
      >
        View
      </Link>
    ),
  }),
]

export const mobilityColumns = (
  handleShowRide: (_id: string) => void,
  currentPage: any
) => [
  columnHelper.accessor("orderId", {
    header: "Order ID",
    enableSorting: false,
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("riderName", {
    header: "Rider Name",
    enableSorting: false,
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("status", {
    header: "Ride Status",
    enableSorting: false,
    cell: (info: any) =>
      info.getValue() ? <RoleStatusBadge type={info.getValue()} /> : null,
  }),
  columnHelper.accessor("distanceTraveled", {
    header: "Distance Traveled",
    enableSorting: false,
    cell: (info: any) => info.getValue()?.toFixed(2),
  }),
  columnHelper.accessor("from", {
    header: "From",
    enableSorting: false,
    cell: (info: any) => (
      <div className={styles.ellipsis}>{info.getValue()}</div>
    ),
  }),
  columnHelper.accessor("to", {
    header: "To",
    enableSorting: false,
    cell: (info: any) => (
      <div className={styles.ellipsis}>{info.getValue()}</div>
    ),
  }),
  columnHelper.accessor("totalAmount", {
    header: "Total Amount",
    enableSorting: false,
    cell: (info: any) => `₹${padDecimals(info.getValue())}`,
  }),
  columnHelper.accessor("orderId", {
    header: "Action",
    enableSorting: false,
    cell: (info: any) => (
      <Link
        // to={`/orders/${info.row.original.orderId}`}
        to=""
        className="txt-12 clr-primary no-underline"
        onClick={() => handleShowRide(info.row.original.orderId)}
      >
        View
      </Link>
    ),
  }),
]
