import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ActionIcon, Switch, Tooltip } from "@mantine/core"
import { OpenStatusBadge } from "components/Common/Badge"
import { brandType } from "types/brand"
import { editBrand } from "services/apis/admin"
import { checkAuth, getDisplayTime, isOutletOpen } from "utils/helper"
import { useBrands, useNotification } from "context"
import {
  PencilIconSVG,
  RatingStarGraySVG as RatingStarGray,
} from "assets/icons/index"

const BrandRow = ({ brand }: { brand: brandType }) => {
  const [brandOutlets, setBrandOutlets] = useState("")
  const [isBrandActive, setIsBrandActive] = useState(brand.isActive)
  const { setEditBrandModal } = useBrands()
  const navigate = useNavigate()
  const { setText } = useNotification()

  useEffect(() => {
    setBrandOutlets(brand.outlets.map((outlet) => outlet.name).join(", "))
  }, [])

  async function handleIsActiveChange() {
    checkAuth(
      async () => {
        const response = await editBrand(brand._id, {
          isActive: !isBrandActive,
        })
        if (response.success) {
          setIsBrandActive(!isBrandActive)
        }
      },
      setText,
      navigate
    )
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        navigate(`/brands/${brand._id}`)
      }}
      className="grid grid-cols-5 grid-rowh-75 bd-btm cursor-ptr"
    >
      <div className="flex flex-col align-start justify-center pl-30 pr-30">
        <p className="txt-14 txtw-5">{brand?.name}</p>
        <p className="txt-12 txtw-5 clr-grey">
          In {brand.outlets.length} Outlets
          {brand.outlets.length > 0 && (
            <Tooltip
              label={brandOutlets}
              classNames={{
                tooltip: "txt-12",
              }}
            >
              <span className="txt-12 bg-gray clr-white pt-3 pb-3 pl-8 pr-8 brd-circle ml-5">
                !
              </span>
            </Tooltip>
          )}
        </p>
      </div>
      <p className="flex justify-center align-center pt-20 pr-20 pb-20 clr-grey">
        {brand.avgRating}
        <span className="rating-img">
          <RatingStarGray />
        </span>
      </p>
      <div className="flex flex-col align-start justify-center pl-30 pr-30">
        <OpenStatusBadge
          type={isOutletOpen(brand.openingTime, brand.closingTime)}
        />
        <p className="txt-12 txtw-5 clr-grey mt-5">{`${getDisplayTime(
          brand.openingTime
        )} - ${getDisplayTime(brand.closingTime)}`}</p>
      </div>
      <div className="flex align-center justify-start pl-30 pr-30">
        <div
          onClick={(e) => {
            e.stopPropagation()
            setEditBrandModal({
              isOpen: true,
              brandId: brand._id,
            })
          }}
        >
          <ActionIcon
            style={{
              marginRight: "8px",
            }}
          >
            <PencilIconSVG />
          </ActionIcon>
        </div>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation()
        }}
        className="flex flex-col align-start justify-center pl-30 pr-30"
      >
        <Switch
          checked={isBrandActive}
          onChange={handleIsActiveChange}
          color="primary"
          classNames={{
            track: "cursor-ptr",
          }}
        />
      </div>
    </div>
  )
}

export default BrandRow
