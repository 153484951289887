import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts"

type Props = {
  data: {
    [key: string]: any
  }[]
  barSize?: number
  dataKeys: Array<{
    fill?: string
    key: string
    stackId?: string
    radius?: number | [number, number, number, number]
    barSize?: number
  }>
  YAxisDataKeys?: string[]
  yaxis?: {
    ticks?: (string | number)[] | undefined
  }
}

const tickFormatter = (value: string, index: number) => {
  const limit = 10 // put your maximum character
  if (value?.length < limit) return value
  return `${value.substring(0, limit)}...`
}

function BarChartComponent({ data, dataKeys, yaxis, barSize }: Props) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={600} height={300} data={data} barSize={barSize || 20}>
        <XAxis
          dataKey={data[0] ? Object.keys(data[0])[0] : ""}
          axisLine={false}
          tickMargin={10}
          interval={0}
          tickLine={false}
          tickFormatter={tickFormatter}
          // padding={{ right: 10, left: 10 }}
        />
        <YAxis />
        <Tooltip cursor={{ fill: "transparent" }} />
        <CartesianGrid strokeDasharray="1 3" vertical={false} />
        {dataKeys.map(({ key, fill, stackId, radius }, index) => (
          <Bar
            key={index}
            dataKey={key}
            fill={fill}
            stackId={stackId}
            radius={
              radius || typeof radius === "number" ? radius : [4, 4, 0, 0]
            }
          />
        ))}
        {/* <Legend /> */}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default BarChartComponent
