import { useState, useEffect, ChangeEvent, MouseEvent } from "react"
import { useUpdateTokens } from "utils/hooks"
import { currentRoleIsUser, getDisplayDate } from "utils/helper"
import { Header } from "components/Common/Header"
import { Button } from "components/Common/Button"
import { SearchBarContainer } from "components/Common/SearchBarContainer"
import { DeleteModal } from "components/Common/DeleteModal"
import AddOfferModal from "components/Offers/AddOffer/AddOfferModal"
import { PaginationContainer } from "components/Common/PaginationContainer"
import { EmptyPage } from "components/Common/EmptyPage"
import { NoOffersSVG, PencilIconSVG, TrashboxIconSVG } from "assets/icons/index"
import {
  useDeleteOfferMutation,
  useGetOfferListQuery,
  useUpdateOfferMutation,
} from "hooks/Admin/offersAPI"
import CustomTable from "components/CustomTable/CustomTable"
import { offerType } from "types/offer"
import { createColumnHelper } from "@tanstack/table-core"
import { ActionIcon, Divider, Switch } from "@mantine/core"
import styles from "./Offers.module.scss"
// import RoleStatusBadge from "components/Common/Badge/RoleStatus"
import defaultOfferBanner from "assets/images/default_offer_banner.png"
import { getStorageItem } from "services/storage"

type OffersAPIOptionsType = {
  page: number
  size: string
}

const Offers = () => {
  const [offersAPIOptions, setOfferAPIOptions] = useState<OffersAPIOptionsType>(
    {
      page: 1,
      size: "10",
    }
  )

  const { data: offersList } = useGetOfferListQuery({
    page: offersAPIOptions?.page,
    size: offersAPIOptions?.size,
  })

  const [isAddOfferModalVisible, setAddOfferModalVisible] = useState(false)
  const [isRemoveOfferModalVisible, setRemoveOfferModalVisible] =
    useState(false)
  const [editOfferModal, setEditOfferModal] = useState({
    isOpen: false,
    offer: "",
  })
  const [toBeDeletedOffer, setToBeDeletedOffer] = useState("")
  const [offerVisible, setOfferVisible] = useState(true)
  useUpdateTokens()

  const onPageChange = (value: number) =>
    setOfferAPIOptions((prev) => ({ ...prev, page: value }))
  const onRecordsSizeChange = (value: string) =>
    setOfferAPIOptions((prev) => ({ ...prev, size: value }))

  const checkAdmin = () => {
    if (
      getStorageItem("role") === "PROTEAN_SUPER_ADMIN" ||
      getStorageItem("role") === "BUYER_APP_SUPER_ADMIN"
    ) {
      setOfferVisible(true)
    } else {
      setOfferVisible(false)
    }
  }
  useEffect(() => {
    checkAdmin()
  }, [])

  useEffect(() => {
    if (toBeDeletedOffer.length > 0) {
      setRemoveOfferModalVisible(true)
    }
  }, [toBeDeletedOffer])
  const [
    deleteOffer, // This is the mutation trigger
  ] = useDeleteOfferMutation()

  const [
    updateOffer, // This is the mutation trigger
    // { isLoading: isUpdating }, // This is the destructured mutation result
  ] = useUpdateOfferMutation()

  async function handleRemoveOffer() {
    const response = await deleteOffer(toBeDeletedOffer)
    if (response.data.success) {
      setRemoveOfferModalVisible(false)
    }
  }

  const onChangeStatus =
    (data: any, status: any, action: string) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      let payload = data
      if (action === "active") {
        payload = {
          ...payload,
          img: payload.img._id,
          isActive: !status,
        }
      } else if (action === "visible") {
        payload = {
          ...payload,
          img: payload.img._id,
          isVisible: !status,
        }
      }
      if (payload?.subCategory?.category) {
        payload = {
          ...payload,
          subCategory: payload.subCategory.category,
        }
      }

      if (payload?.category) {
        payload = {
          ...payload,
          category: payload.category._id,
        }
      }
      delete payload._id
      delete payload.createdAt
      delete payload.updatedAt
      delete payload.__v
      updateOffer({ id: data._id, payload })
    }

  const onDeleteOffer = (_id: string) => (event: MouseEvent<HTMLElement>) => {
    setToBeDeletedOffer(_id)
  }

  const columnHelper = createColumnHelper<offerType>()

  const columns = [
    columnHelper.accessor("_id", {
      header: "Offer ID",
      enableSorting: false,
      cell: (info: any) => (
        <div className={styles.ellipsis}>{info.getValue()}</div>
      ),
    }),
    columnHelper.accessor("title", {
      header: "Title",
      enableSorting: false,
      cell: (info: any) => info.getValue(),
    }),
    columnHelper.accessor("img", {
      header: "Image Cover",
      enableSorting: false,
      cell: (info: any) => (
        <img
          src={info.row.original.img.link || defaultOfferBanner}
          alt="Offer Cover"
          style={{
            height: "50px",
            width: "50px",
            margin: "auto",
            objectFit: "contain",
          }}
        />
      ),
    }),
    columnHelper.accessor("startDate", {
      header: "Start Date",
      enableSorting: false,
      cell: (info: any) => getDisplayDate(info.getValue()),
    }),
    columnHelper.accessor("endDate", {
      header: "End Date",
      enableSorting: false,
      cell: (info: any) => getDisplayDate(info.getValue()),
    }),
    columnHelper.accessor("isActive", {
      header: "Active",
      enableSorting: false,
      cell: (info: any) => (
        <Switch
          disabled={!offerVisible}
          defaultChecked={info.getValue()}
          onChange={onChangeStatus(
            info.row.original,
            info.getValue(),
            "active"
          )}
          color="primary"
          classNames={{ track: "cursor-ptr" }}
        />
      ),
    }),
    columnHelper.accessor("isVisible", {
      header: "Visible",
      enableSorting: false,
      cell: (info: any) => (
        <Switch
          disabled={!offerVisible}
          defaultChecked={info.getValue()}
          onChange={onChangeStatus(
            info.row.original,
            info.getValue(),
            "visible"
          )}
          color="primary"
          classNames={{ track: "cursor-ptr" }}
        />
      ),
    }),
    {
      id: "action",
      header: "Action",
      enableSorting: false,
      cell: (info: any) => (
        <div className={styles.action} style={{ display: "flex" }}>
          <ActionIcon
            disabled={!offerVisible}
            onClick={() => {
              setEditOfferModal({
                isOpen: true,
                offer: info.row.original,
              })
            }}
          >
            <PencilIconSVG />
          </ActionIcon>
          <ActionIcon
            onClick={onDeleteOffer(info.row.original._id)}
            disabled={!offerVisible}
          >
            <TrashboxIconSVG />
          </ActionIcon>
        </div>
      ),
      size: 80,
    },
  ]

  const columnVisibility = {
    action: !currentRoleIsUser(),
  }

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main bg-white flex-1">
        <div className="section-header">Offers</div>
        <div className="pg-subheader h-40 flex justify-stretch align-center mb-30 mx-80">
          <SearchBarContainer
            item={["Offer", "Offers"]}
            itemsCount={offersList?.data?.count}
            listingCount={offersList?.data?.updated_offers?.length}
          />
          {offerVisible ? (
            <Button
              type="filled-primary"
              text="Add Offer"
              onClick={() => setAddOfferModalVisible(true)}
              classNames={{
                root: "add-admin-btn brd-10",
                label: "txt-14 txtw-5",
              }}
            />
          ) : null}
        </div>
        <Divider className="mx-80 pb-20" color="#D0D5DD" />
        {!!offersList?.data?.updated_offers?.length ? (
          <>
            <section className="min-h-70 bd-gray  flex-col bg-white mt-5 mb-5 mx-80">
              <CustomTable
                columns={columns}
                data={offersList?.data?.updated_offers}
                className={styles.table}
                columnVisibility={columnVisibility}
              />
            </section>
            <PaginationContainer
              page={offersAPIOptions.page}
              setPage={onPageChange}
              size={offersAPIOptions.size}
              setSize={onRecordsSizeChange}
              totalEntries={offersList?.data?.count}
            />
          </>
        ) : (
          <EmptyPage img={NoOffersSVG} item="Offers" />
        )}
      </main>
      <AddOfferModal
        editOfferModal={editOfferModal}
        isAddOfferModalVisible={isAddOfferModalVisible}
        setAddOfferModalVisible={setAddOfferModalVisible}
        setEditOfferModal={setEditOfferModal}
      />
      <DeleteModal
        isDeleteModalOpen={isRemoveOfferModalVisible}
        closeDeleteModal={() => {
          setRemoveOfferModalVisible(false)
          setToBeDeletedOffer("")
        }}
        data={{
          text: "offer",
        }}
        onConfirm={handleRemoveOffer}
      />
    </div>
  )
}

export default Offers
