import { getDisplayTime } from "utils/helper"
import { useBrand } from "context"
import {
  ClockSVG as Clock,
  EnvelopePrimarySVG,
  ForkKnifeSVG,
  RightArrowSVG,
  StorefrontSVG,
} from "assets/icons/index"

const BrandDetailsPanel = () => {
  const { brand } = useBrand()

  return (
    <section className="ml-80 bg-white brd-10 pt-20 pr-20 pb-20 pl-20 brandinfo-section">
      <div className="bd-btm">
        <p className="txt-ucase txt-12 txtw-5 clr-grey">brand details</p>
        <div className="flex align-center mt-15 mb-15">
          <img src={StorefrontSVG} alt="brand" className="w-18 mr-10" />
          <p className="txt-14 txtw-4">{brand?.name ?? ""}</p>
        </div>
        <div className="flex mt-15 mb-15">
          <span className="flex align-center w-18 mr-10">
            <Clock />
          </span>
          <p className="txt-14 txtw-4">
            {`${getDisplayTime(brand?.openingTime ?? "")} - ${getDisplayTime(
              brand?.closingTime ?? ""
            )}`}
          </p>
        </div>
        <div className="flex mt-15 mb-15">
          <span className="flex align-center w-18 mr-10">
            <EnvelopePrimarySVG />
          </span>
          <p className="txt-14 txtw-4">{brand?.email ?? ""}</p>
        </div>
      </div>
      {/* <div className="flex align-center bd-btm pt-10 pb-10">
                <p className="txt-14 txtw-4 mr-5 cursor-ptr clr-primary">
                    View Menu and Photos
                </p>
                <RightArrowSVG />
            </div> */}
      <div className="bd-btm pt-10 pb-10">
        <p className="txt-ucase txt-12 txtw-5 clr-grey">description</p>
        <p className="txt-14 txtw-4 mt-5">{brand?.description ?? ""}</p>
      </div>
      <div className="pt-10">
        <p className="txt-ucase txt-12 txtw-5 clr-grey">uploaded media</p>
        <div className="flex justify-start align-center pt-10 pr-10 pb-10 pl-10 bd-gray brd-10 mt-10 mb-10">
          {brand?.media?.logo?.length > 0 && (
            <img
              src={brand?.media?.logo ?? ""}
              alt="logo"
              className="pt-20 pr-20 pb-20 pl-20 w-100 img-cover bg-gray brd-10"
            />
          )}
          <p className="clr-grey txt-14 txtw-5 ml-20">logo</p>
        </div>
        <div className="flex justify-start align-center pt-10 pr-10 pb-10 pl-10 bd-gray brd-10 mt-10 mb-10">
          {brand?.media?.intro?.length > 0 && (
            <img
              src={brand?.media?.intro ?? ""}
              alt="intro"
              className="w-100 img-cover brd-10"
            />
          )}
          <p className="clr-grey txt-14 txtw-5 ml-20">intro</p>
        </div>
      </div>
    </section>
  )
}

export default BrandDetailsPanel
