import { useEffect, useState } from "react"
import { Accordion, Checkbox, Radio, SegmentedControl } from "@mantine/core"
import {
  DateRangePicker,
  DateRangePickerValue,
  TimeInput,
} from "@mantine/dates"
import { Button } from "components/Common/Button"
import { Modal } from "components/Common/Modal"

import {
  DELIVERY_MODES,
  INITIAL_ORDERS_FILTERS,
  ISSUE_STATUS,
  MODES_OF_PAYMENTS,
  ORDER_MANAGEMENT_STATUS,
} from "constants/index"
import {
  generateUrlSearchParams,
  isLocationOutletInFilter,
  resetOrdersFilter,
  updateBrandsFilter,
  updateDeliveryModesFilter,
  updateIssuesStatusFilter,
  updateLocationOutletFilter,
  updateModesOfPaymentFilter,
} from "utils/helper"
import { useFilters } from "context"
import { constant } from "./constant"
import { getStorageItem, setStorageItem } from "services/storage"
import { RadioGroup } from "@mantine/core/lib/Radio/RadioGroup/RadioGroup"
import { stat } from "fs"

const OrderFilters = ({
  isFiltersModalVisible,
  type,
  onFiltersChange = () => {},
  setFilterCount,
}: {
  isFiltersModalVisible: boolean
  type: string
  onFiltersChange: (val: string) => void
  setFilterCount: any
}) => {
  const [currentFilterSelected, setCurrentFilterSelected] = useState("filter")
  const [cityWiseOutlets, setCityWiseOutlets] = useState<any>({})
  const [allBrands, setAllBrands] = useState([])
  const [allModesOfPayment, setAllModesOfPayments] = useState(MODES_OF_PAYMENTS)
  const [deliveryModes, setDeliveryModes] = useState(DELIVERY_MODES)
  const [ordersFilters, setOrdersFilters] = useState<any>(
    INITIAL_ORDERS_FILTERS
  )
  const [issueStatus, setIssueStatus] = useState(ISSUE_STATUS)
  const [orderManagemntStatus, setOrderManagemetnStatus] = useState(
    ORDER_MANAGEMENT_STATUS
  )
  const { setIsFiltersModalVisible } = useFilters()

  useEffect(() => {
    if (!isFiltersModalVisible) {
      updateOrdersFilter("reset", {})
    }
  }, [isFiltersModalVisible])

  useEffect(() => {
    if (type) {
      let data = getStorageItem("filters")

      if (data && data[type]) {
        setOrdersFilters(data[type])
      }
    }
    setCurrentFilterSelected(constant[type][0]?.value)
  }, [type])

  function updateOrdersFilter(filter: string, val: any) {
    let newOrdersFilters = { ...ordersFilters }
    switch (filter) {
      case "location-outlet":
        if (val.all) {
          if (
            newOrdersFilters["location-outlet"][val.city].length ===
            cityWiseOutlets[val.city].length
          ) {
            newOrdersFilters["location-outlet"][val.city] = []
          } else {
            newOrdersFilters["location-outlet"][val.city] =
              cityWiseOutlets[val.city]
          }
        } else {
          newOrdersFilters = updateLocationOutletFilter(newOrdersFilters, val)
        }
        break
      case "brands":
        newOrdersFilters = updateBrandsFilter(newOrdersFilters, val)
        break
      case "date-time":
        newOrdersFilters["date-time"][val.type] = val.value
        break
      case "modes-of-payment":
        newOrdersFilters = updateModesOfPaymentFilter(newOrdersFilters, val)
        break
      case "delivery-mode":
        newOrdersFilters = updateDeliveryModesFilter(newOrdersFilters, val)
        break
      case "status":
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          val,
          "status"
        )
        break
      case "reset":
        newOrdersFilters = {}
        break
      default:
        break
    }
    countFilters(newOrdersFilters)
    setOrdersFilters({ ...newOrdersFilters })
  }
  function countFilters(newOrderFilters: any) {
    if (newOrderFilters) {
      let count = Object.values(newOrderFilters).reduce(
        (accumulator, currentArray: any) => {
          // Check if the currentArray has values and they are not empty
          if (currentArray.length > 0) {
            // Increment the accumulator by the number of values in the currentArray
            accumulator += currentArray.length
          }
          return accumulator
        },
        0
      )

      setFilterCount(count)
    } else {
      setFilterCount(0)
    }
  }
  function isValueInFilter(filter: string, val?: any) {
    switch (filter) {
      case "location-outlet":
        if (val.all) {
          if (
            ordersFilters["location-outlet"][val.city]?.length ===
            cityWiseOutlets[val.city]?.length
          ) {
            return true
          } else {
            return false
          }
        } else {
          return isLocationOutletInFilter({ ...ordersFilters }, val)
        }
      case "brands":
        return ordersFilters["brands"]?.indexOf(val.brand) === -1 ? false : true
      case "modes-of-payment":
        return ordersFilters["modes-of-payment"].indexOf(val.mop) === -1
          ? false
          : true
      case "delivery-mode":
        return ordersFilters["delivery-mode"].indexOf(val.dm) === -1
          ? false
          : true
      case "status":
        return ordersFilters["status"].toString()
    }
  }

  return (
    <Modal
      title="Filters"
      opened={isFiltersModalVisible}
      onClose={() => setIsFiltersModalVisible(false)}
     
    >
      <div className="flex align-start bd-btm pt-10 pb-10 " >
        <SegmentedControl
          value={currentFilterSelected}
          onChange={setCurrentFilterSelected}
          data={constant[type]}
          classNames={{
            label: "pt-10 pr-10 pb-10 pl-10",
          }}
          orientation="vertical"
        />
        <div className="w-400 pl-10 ml-10 bd-left">
          {currentFilterSelected === "location-outlet" ? (
            <Accordion
              classNames={{
                label: "txt-12 txtw-5",
              }}
              defaultValue="Pune"
            >
              {Object.keys(cityWiseOutlets)?.map((city) => (
                <Accordion.Item key={city} value={city}>
                  <Accordion.Control>{city}</Accordion.Control>
                  <Accordion.Panel>
                    <Checkbox
                      checked={isValueInFilter("location-outlet", {
                        city,
                        outlets: cityWiseOutlets[city].join(","),
                        all: true,
                      })}
                      onChange={() =>
                        updateOrdersFilter("location-outlet", {
                          city,
                          outlets: cityWiseOutlets[city].join(","),
                          all: true,
                        })
                      }
                      label="Select All"
                      classNames={{
                        root: "mt-8 mb-8",
                        label: "txt-12 txtw-4",
                      }}
                    />
                    {cityWiseOutlets[city]?.map((outlet: string) => (
                      <Checkbox
                        key={outlet}
                        checked={isValueInFilter("location-outlet", {
                          city,
                          outlets: outlet,
                        })}
                        onChange={(e) =>
                          updateOrdersFilter("location-outlet", {
                            city,
                            outlets: outlet,
                          })
                        }
                        label={outlet}
                        classNames={{
                          root: "mt-8 mb-8",
                          label: "txt-12 txtw-4",
                        }}
                      />
                    ))}
                  </Accordion.Panel>
                </Accordion.Item>
              ))}
            </Accordion>
          ) : currentFilterSelected === "brand" ? (
            <div>
              {allBrands?.map((brand) => (
                <div className="flex justify-stretch mt-15 mb-15">
                  <Checkbox
                    checked={isValueInFilter("brands", {
                      brand,
                    })}
                    onChange={() =>
                      updateOrdersFilter("brands", {
                        brand,
                      })
                    }
                    label={brand}
                    classNames={{
                      root: "flex row-reverse justify-end  w-full",
                      label: "txt-12 txtw-5",
                    }}
                  />
                </div>
              ))}
            </div>
          ) : currentFilterSelected === "date-time" ? (
            <div className="date-picker-container">
              <DateRangePicker
                value={ordersFilters["date-time"].date}
                onChange={(val) =>
                  updateOrdersFilter("date-time", {
                    type: "date",
                    value: val,
                  })
                }
                label="Order dates range"
                placeholder="FROM - TILL"
                classNames={{
                  day: "day",
                }}
              />
              <div className="flex justify-even mt-30">
                <TimeInput
                  value={ordersFilters["date-time"].from}
                  onChange={(val) =>
                    updateOrdersFilter("date-time", {
                      type: "from",
                      value: val,
                    })
                  }
                  label="FROM"
                  format="12"
                  clearable
                  classNames={{
                    wrapper: "w-150",
                  }}
                />
                <TimeInput
                  value={ordersFilters["date-time"].till}
                  onChange={(val) =>
                    updateOrdersFilter("date-time", {
                      type: "till",
                      value: val,
                    })
                  }
                  label="TILL"
                  format="12"
                  clearable
                  classNames={{
                    wrapper: "w-150",
                  }}
                />
              </div>
            </div>
          ) : currentFilterSelected === "payment" ? (
            <div>
              {allModesOfPayment?.map((mop) => (
                <div key={mop} className="flex justify-stretch mt-15 mb-15">
                  <Checkbox
                    checked={isValueInFilter("modes-of-payment", { mop })}
                    onChange={() =>
                      updateOrdersFilter("modes-of-payment", { mop })
                    }
                    label={mop}
                    classNames={{
                      root: "flex row-reverse justify-end  w-full",
                      label: "txt-12 txtw-5",
                    }}
                  />
                </div>
              ))}
            </div>
          ) : currentFilterSelected === "delivery" ? (
            <div>
              {deliveryModes?.map((dm) => (
                <div key={dm} className="flex justify-stretch mt-15 mb-15">
                  <Checkbox
                    checked={isValueInFilter("delivery-mode", { dm })}
                    onChange={() => updateOrdersFilter("delivery-mode", { dm })}
                    label={dm}
                    classNames={{
                      root: "flex row-reverse justify-end  w-full",
                      label: "txt-12 txtw-5",
                    }}
                  />
                </div>
              ))}
            </div>
          ) : currentFilterSelected === "issueStatus" ? (
            <div className="flex justify-stretch mb-15">
              {/* <Checkbox
                    checked={isValueInFilter("issue-status", { status })}
                    onChange={() =>
                      updateOrdersFilter("issue-status", { status })
                    }
                    label={status}
                    classNames={{
                      root: "flex row-reverse justify-end  w-full",
                      label: "txt-12 txtw-5",
                    }}
                  /> */}
              <Radio.Group
                withAsterisk
                classNames={{
                  root: "flex flex-col gcol-start-1 gcol-end-3 ",
                  label: "txt-12 txtw-4 clr-grey txt-ucase",
                }}
                defaultValue={isValueInFilter("status")}
                onChange={(e) => {
                  updateOrdersFilter("status", e)
                }}
              >
                <div className="flex flex-col">
                  {issueStatus?.map((status,id) => (
                    <Radio
                    key={status+'_'+id}
                      value={status}
                      label={status}
                      className="mb-10"
                      classNames={{
                        label: "txt-12 txtw-4 clr-grey txt-cap",
                      }}
                    />
                  ))}
                </div>
              </Radio.Group>
            </div>
          ) : currentFilterSelected === "status" ? (
            <div className="flex justify-stretch mb-15">
              {/* <Checkbox
                    checked={isValueInFilter("issue-status", { status })}
                    onChange={() =>
                      updateOrdersFilter("issue-status", { status })
                    }
                    label={status}
                    classNames={{
                      root: "flex row-reverse justify-end  w-full",
                      label: "txt-12 txtw-5",
                    }}
                  /> */}
              <Radio.Group
                withAsterisk
                classNames={{
                  root: "flex flex-col gcol-start-1 gcol-end-3 ",
                  label: "txt-12 txtw-4 clr-grey txt-ucase",
                }}
                defaultValue={isValueInFilter("status")}
                onChange={(e) => {
                  updateOrdersFilter("status", e)
                }}
              >
                <div className="flex flex-col">
                  {orderManagemntStatus?.map((status) => (
                    <Radio
                      value={status}
                      label={status}
                      className="mb-10"
                      classNames={{
                        label: "txt-12 txtw-4 clr-grey txt-cap",
                      }}
                    />
                  ))}
                </div>
              </Radio.Group>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="flex justify-stretch align-center pt-10">
        <Button
          type="default"
          text="Reset"
          onClick={() => {
            updateOrdersFilter("reset", {})
            let obj: any = ordersFilters
            setStorageItem("filters", obj)
            // let data:string=generateUrlSearchParams(obj[`${type}`])
            onFiltersChange(obj[type])
            setIsFiltersModalVisible(false)
          }}
        />
        <Button
          type="filled-primary"
          text="Apply"
          onClick={() => {
            // setIsFiltersModalVisible(false)
            let obj: any = getStorageItem("filters") || {}
            obj[type] = ordersFilters
            setStorageItem("filters", obj)
            // let data:string=generateUrlSearchParams(obj[`${type}`])
            onFiltersChange(obj[type])
            setIsFiltersModalVisible(false)
          }}
        />
      </div>
    </Modal>
  )
}

export default OrderFilters
