import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useForm } from "@mantine/form"
import { Input, NumberInput } from "@mantine/core"
import { Button } from "./Common/Button"
import { Modal } from "./Common/Modal"
import { useCoins, useNotification } from "context"
import { getCoinValue, patchCoinValue } from "services/apis/admin"
import { checkAuth } from "utils/helper"
import CoinSVG from "assets/icons/Coin.svg"

const CoinsModal = () => {
  const [coinValue, setCoinValue] = useState("")
  const {
    isCoinsVisible,
    setIsCoinsVisible,
    coinsApiInProgress,
    setCoinsApiInProgress,
  } = useCoins()
  const { setText } = useNotification()
  const navigate = useNavigate()
  const coinValueForm = useForm({
    validateInputOnChange: true,
    initialValues: {
      value: "",
    },
    validate: {
      value: (val) => (val?.length === 0 ? "Value is required" : null),
    },
  })

  useEffect(() => {
    ;(async () => {
      if (isCoinsVisible) {
        checkAuth(
          async () => {
            const response = await getCoinValue()
            if (response.success) {
              setCoinValue(response.data)
            }
          },
          setText,
          navigate
        )
      }
    })()
  }, [isCoinsVisible])

  async function handleCoinValueUpdate() {
    checkAuth(
      async () => {
        const response = await patchCoinValue(coinValueForm.values.value)
        if (response.success) {
          setIsCoinsVisible(false)
          coinValueForm.setValues({
            value: "",
          })
        }
      },
      setText,
      navigate,
      setCoinsApiInProgress
    )
  }

  function handleBasicDetailsSubmit() {
    handleCoinValueUpdate()
  }

  function handleBasicDetailsError() {}

  return (
    <Modal
      opened={isCoinsVisible}
      title="Coin"
      onClose={() => {
        setIsCoinsVisible(false)
      }}
    >
      <form
        onSubmit={coinValueForm.onSubmit(
          handleBasicDetailsSubmit,
          handleBasicDetailsError
        )}
      >
        <div className="w-300">
          <div className="flex align-center">
            <img src={CoinSVG} alt="coin" className="mr-5" />
            <p className="clr-grey txt-20 txtw-4">
              {coinValue} coin&#40;s&#41;
            </p>
          </div>
          <Input.Wrapper
            classNames={{
              root: "mt-30 mb-30",
              label: "clr-grey txt-12 txtw-4",
            }}
            label="UPDATE COIN VALUE"
          >
            <NumberInput
              classNames={{
                wrapper: "clr-grey txt-12 txtw-4",
              }}
              placeholder="Number of coins in ₹ 1"
              variant="filled"
              {...coinValueForm.getInputProps("value")}
            />
          </Input.Wrapper>
          <div className="flex justify-end bd-top mt-10 pt-10">
            <Button
              type="filled-primary"
              text="Save"
              loading={coinsApiInProgress}
              onClick={() => {}}
            />
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default CoinsModal
