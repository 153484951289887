import { Input } from "@mantine/core"
import PhoneInput from "react-phone-input-2"

const PhoneInputField = ({
  label,
  phone,
  validationText,
  handlePhoneValidation,
}: {
  label: string
  phone: string
  validationText: string
  handlePhoneValidation: Function
}) => {
  return (
    <Input.Wrapper
      classNames={{
        label: "clr-grey txt-12 txtw-4",
      }}
      label={label}
      required
    >
      <PhoneInput
        country={"in"}
        value={phone}
        onChange={(val) => handlePhoneValidation(label, val)}
        inputStyle={{
          background: "#EAECF0",
          borderStyle: validationText ? "solid" : "none",
          borderColor: validationText && "red",
          width: "250px",
          borderRadius: "5px",
        }}
        buttonStyle={{
          borderLeftStyle: validationText ? "solid" : "none",
          borderBottomStyle: validationText ? "solid" : "none",
          borderTopStyle: validationText ? "solid" : "none",
          borderRightStyle: "none",
          borderColor: validationText && "red",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
        }}
        placeholder="Enter Phone"
      />
      {validationText && (
        <p className="txt-12 clr-red mt-5">{validationText}</p>
      )}
    </Input.Wrapper>
  )
}

export default PhoneInputField
