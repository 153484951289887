import { Modal } from "components/Common/Modal"
import { Button } from "components/Common/Button"
import { Input, Switch, TextInput } from "@mantine/core"
import { ImageUpload } from "components/Common/ImageUpload"
import React, { useEffect, useState } from "react"
import { useForm, yupResolver } from "@mantine/form"
import * as Yup from "yup"
import { useAdminTypeContext } from "context/adminType"

type ImageType = {
  link: string
  _id: string
}

type FormValues = {
  subCategoryName: string
  category: string
  image: ImageType | null
  visible: boolean
  _id: string | null
  code?: string | null
}

const B2CSchema = Yup.object().shape({
  subCategoryName: Yup.string().required("Sub Category name is required"),
  image: Yup.object()
    .shape({
      link: Yup.string(),
      _id: Yup.string(),
    })
    .optional(),
  visible: Yup.boolean().optional(),
})

const B2BSchema = Yup.object().shape({
  subCategoryName: Yup.string().required("Sub Category name is required"),
  image: Yup.object()
    .shape({
      link: Yup.string(),
      _id: Yup.string(),
    })
    .optional(),
  visible: Yup.boolean().optional(),
  code: Yup.string().required("Code is required"),
})
const SubCategoryEditModal = ({
  open,
  isAddModal,
  onSave,
  onUpdate,
  onClose,
  selectedSubCategory,
  categoryId,
}: {
  open: boolean
  isAddModal: boolean
  onSave: (data: any) => void
  onUpdate: (data: any) => void
  onClose: () => void
  selectedSubCategory?: any
  categoryId: string
}) => {
  const { adminType } = useAdminTypeContext()

  const form = useForm<FormValues>({
    initialValues: {
      subCategoryName: "",
      category: categoryId,
      image: null,
      visible: true,
      _id: null,
    },
    validate:
      adminType === "e-commerce-b2b"
        ? yupResolver(B2BSchema)
        : yupResolver(B2CSchema),
  })

  const onSubmit = (data: any) => {
    console.log(data, "Data")
    if (selectedSubCategory) {
      onUpdate(data)
    } else {
      onSave(data)
    }
  }

  const onModalClose = () => {
    form.reset()
    onClose()
  }
  useEffect(() => {
    if (selectedSubCategory) {
      form.setValues({
        subCategoryName: selectedSubCategory.subCategoryName,
        category: selectedSubCategory.category,
        image: selectedSubCategory.image,
        visible: selectedSubCategory.visible,
        _id: selectedSubCategory._id,
        code: selectedSubCategory.code,
      })
    }
  }, [selectedSubCategory])

  console.log(form.errors.image)

  return (
    <Modal
      opened={open}
      onClose={onModalClose}
      title={isAddModal ? "Add Sub Category" : "Edit Sub Category"}
    >
      <form
        className="pt-20 pl-20 pr-20 w-500"
        onSubmit={form.onSubmit(onSubmit)}
      >
        <div className="flex justify-center align-center flex-col">
          <ImageUpload
            mediaTypes={["image/png", "image/jpeg", "image/svg+xml"]}
            media={form.values?.image?.link}
            setMedia={(img) => form.setFieldValue("image", img[0])}
            mediaValidationText={(form?.errors?.image as string) || undefined}
            setMediaValidationText={(err) => form.setFieldError("image", err)}
            mediaSize={2000000}
          />
        </div>
        {adminType === "e-commerce-b2b" && (
          <TextInput
            label="CODE"
            classNames={{
              wrapper: "clr-grey txt-12 txtw-4 pb-10",
              label: "clr-grey txt-12 txtw-4",
            }}
            className="cursor-default"
            variant="filled"
            {...form.getInputProps("code")}
          />
        )}
        <TextInput
          label="SUBCATEGORY NAME"
          classNames={{
            wrapper: "clr-grey txt-12 txtw-4 pb-10",
            label: "clr-grey txt-12 txtw-4",
          }}
          className="cursor-default"
          variant="filled"
          {...form.getInputProps("subCategoryName")}
        />
        {/* <div className="w-full flex justify-stretch mt-10">
          <Input.Wrapper
            classNames={{
              label: "clr-grey txt-12 txtw-4",
            }}
            label="VISIBLE"
          >
            <Switch
              color="primary"
              classNames={{ track: "cursor-ptr" }}
              {...form.getInputProps('visible')}
            />
          </Input.Wrapper>
        </div> */}
        <div className="flex justify-end  mt-20 pt-10">
          <Button type="filled-primary" text="Save" onClick={() => {}} />
        </div>
      </form>
    </Modal>
  )
}
export default SubCategoryEditModal
