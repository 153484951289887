export const constant: any = {
  order: [
    {
      label: "Created At: High-to-Low",
      value: "createdAt-desc",
      key: "desc",
    },
    {
      label: "Created At: Low-to-High",
      value: "createdAt-asc",
      key: "asc",
    },
    {
      label: "Updated At: High-to-Low",
      value: "updatedAt-desc",
      key: "desc",
    },
    {
      label: "Updated At: Low-to-High",
      value: "updatedAt-asc",
      key: "asc",
    },
    {
      label: "Total Amount: High-to-Low",
      value: "totalAmount-desc",
      key: "desc",
    },
    {
      label: "Total Amount: Low-to-High",
      value: "totalAmount-asc",
      key: "asc",
    },
  ],
  customers: [
    {
      label: "Created At: High-to-Low",
      value: "createdAt-desc",
      key: "desc",
    },
    {
      label: "Created At: Low-to-High",
      value: "createdAt-asc",
      key: "asc",
    },
    {
      label: "Updated At: High-to-Low",
      value: "updatedAt-desc",
      key: "desc",
    },
    {
      label: "Updated At: Low-to-High",
      value: "updatedAt-asc",
      key: "asc",
    },
    {
      label: "Total Amount: High-to-Low",
      value: "totalPrice-desc",
      key: "desc",
    },
    {
      label: "Total Amount: Low-to-High",
      value: "totalPrice-asc",
      key: "asc",
    },
  ],
  ticketManagement: [
    {
      label: "Title: High-to-Low",
      value: "title-desc",
      key: "desc",
    },
    {
      label: "Title: Low-to-High",
      value: "title-asc",
      key: "asc",
    },
  ],
  categories: [
    {
      label: "Category Name: A to Z",
      value: "categoryName-asc",
      key: "desc",
    },
    {
      label: "Category Name: Z to A",
      value: "categoryName-desc",

      key: "asc",
    },
    {
      label: "Visible: Active",
      value: "visible-desc",
      key: "desc",
    },
    {
      label: "Visible: Inactive",
      value: "visible-asc",
      key: "asc",
    },
  ],
}
