import { Button } from "components/Common/Button"
import { PlusSVG as Plus, TrashboxIconSVG } from "assets/icons/index"
import { associatedOutlets } from "types/brand"

const AssociatedOutlets = ({ outlets }: { outlets: associatedOutlets }) => {
  return (
    <section className="pt-50">
      <div className="flex justify-stretch align-center">
        <p className="txt-14 txtw-6 txt-ucase clr-grey">outlets</p>
        {/* <Button
                    leftIcon={<Plus />}
                    type="filled-secondary"
                    text="Add an Outlet"
                    onClick={() => {}}
                /> */}
      </div>
      <div className="grid grid-cols-5 gap-20 mt-15">
        {outlets?.map((outlet, index) => (
          <article className="pos-rel" key={index}>
            <img
              src={outlet.media?.cover}
              alt="outlet"
              className="outlet-img img-cover brd-10"
            />
            <p className="txt-16 txtw-5 clr-grey mt-8">{outlet.name}</p>
            <div className="pos-abs remove-img cursor-ptr">
              <TrashboxIconSVG />
            </div>
          </article>
        ))}
      </div>
    </section>
  )
}

export default AssociatedOutlets
