import { TextInput } from "@mantine/core"
import { useDebouncedValue } from "@mantine/hooks"
import { MagnifyingGlassSVG } from "assets/icons/index"
import { useEffect, useState } from "react"

interface searchBarContainerProps {
  item: string[]
  itemsCount: number
  listingCount: number
}

const SearchBarContainer = ({
  item,
  itemsCount,
  listingCount,
}: searchBarContainerProps) => {
  const [searchBarValue, setSearchBarValue] = useState("")
  const [debouncedSearchBarValue] = useDebouncedValue(searchBarValue, 400)

  const itemText = listingCount === 1 ? item[0] : item[1] || item[0]

  return (
    <div className="search-container flex align-center">
      <p className="mr-10 txt-16 txtw-6 clr-grey">
        {listingCount && listingCount !== 0 ? (
          <>
            Showing {listingCount || 0} {itemText} out of {itemsCount}
          </>
        ) : (
          "No data exists"
        )}
      </p>
      {/* <TextInput
                value={searchBarValue}
                onChange={(e) => setSearchBarValue(e.currentTarget.value)}
                placeholder="Search by order ID, customer, outlet etc.."
                icon={<MagnifyingGlassSVG />}
                classNames={{
                    wrapper: "w-300",
                    input: "search-input",
                }}
            /> */}
    </div>
  )
}

export default SearchBarContainer
