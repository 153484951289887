import { Button } from "components/Common/Button"
import { Modal } from "components/Common/Modal"

const DeleteModal = ({
    isDeleteModalOpen,
    closeDeleteModal,
    data,
    onConfirm,
}: {
    isDeleteModalOpen: boolean
    closeDeleteModal: Function
    data: {
        text: string
    }
    onConfirm: Function
}) => {
    return (
        <Modal
            opened={isDeleteModalOpen}
            onClose={() => closeDeleteModal()}
            title={`Delete ${data.text}`}
        >
            <p className="txt-16 txtw-400">
                Are you sure you want to remove this {data.text} ?
            </p>
            <div className="flex justify-even mt-20">
                <Button
                    type="outline"
                    text="Cancel"
                    onClick={() => closeDeleteModal()}
                />
                <Button
                    type="filled-primary"
                    text="Remove"
                    onClick={() => onConfirm()}
                />
            </div>
        </Modal>
    )
}

export default DeleteModal
