import { useCallback, useState } from "react"
import ImageViewer from "react-simple-image-viewer"
import styles from "./ImagePreview.module.scss"
import { ViewStatusSVG } from "assets/icons"

type Props = { imagesList: string[] }

export default function ImagePreview({ imagesList }: Props) {
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])
  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  return (
    <>
      <div className="flex align-center gap-20 pt-15">
        {imagesList.length > 0 ? (
          imagesList.map((img: string, id: number) => (
            <div className={styles["img-container"]} key={`issue-image-${id}`}>
              <img
                src={img}
                alt={`issue-image-${id}`}
                width="100px"
                height="100px"
                className="img-contain cursor-ptr"
                onClick={() => openImageViewer(id)}
              />

              <div
                className={styles["eye-icon"]}
                onClick={() => openImageViewer(id)}
              >
                <ViewStatusSVG color="white" />
              </div>
            </div>
          ))
        ) : (
          <p className="txt-14 txtw-4 clr-grey">
            There were no images attached
          </p>
        )}
      </div>
      {isViewerOpen && (
        <ImageViewer
          src={imagesList}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.6)",
            backdropFilter: "blur(2px)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </>
  )
}
