import { useEffect, useState } from "react"
// import { useNavigate } from "react-router-dom"
import { Input, Select, TextInput } from "@mantine/core"
import { useForm } from "@mantine/form"
import { Button } from "components/Common/Button"
// import { Dropzone } from "components/Common/Dropzone"
import PhoneInputField from "components/Common/PhoneInput/PhoneInput"
// import { editAdmin, getAdmin } from "services/apis/admin"
// import { useNotification } from "context"
import { DownArrowSVG } from "assets/icons"
// import { checkAuth } from "utils/helper"
import {
  useAddAdminMutation,
  useUpdateAdminMutation,
} from "hooks/Admin/adminAPI"

const BasicDetails = ({
  setAddAdminModalVisible,
  editAdminModal,
  setEditAdminModal,
}: {
  setAddAdminModalVisible: Function
  editAdminModal: any
  setEditAdminModal: Function
}) => {
  // const [adminPhoto, setAdminPhoto] = useState("")
  const [phone, setPhone] = useState("")
  const [phoneValidationText, setPhoneValidationText] = useState("")
  const [adminRole, setAdminRole] = useState("")
  const [adminApiInProgress, setAdminApiInProgress] = useState(false)
  // const navigate = useNavigate()
  const basicDetailsForm = useForm({
    validateInputOnChange: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validate: {
      firstName: (value) =>
        value?.length === 0
          ? "First name is required"
          : /^[a-zA-Z]*$/.test(value)
          ? null
          : "Invalid name",
      lastName: (value) =>
        value?.length === 0
          ? "Last name is required"
          : /^[a-zA-Z]*$/.test(value)
          ? null
          : "Invalid name",
      email: (value) =>
        value?.length === 0
          ? "Email is required"
          : /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
          ? null
          : "Invalid email",
    },
  })
  // const { setText } = useNotification()

  const [
    updateAdmin, // This is the mutation trigger
    // { isLoading: isUpdating }, // This is the destructured mutation result
  ] = useUpdateAdminMutation()

  const [
    addAdmin, // This is the mutation trigger
    // { isLoading: isUpdating }, // This is the destructured mutation result
  ] = useAddAdminMutation()

  useEffect(() => {
    if (editAdminModal.isOpen) {
      basicDetailsForm.setValues({
        firstName: editAdminModal.data.firstName,
        lastName: editAdminModal.data.lastName,
        email: editAdminModal.data.email,
      })
      setPhone(editAdminModal.data.phone)
      if (editAdminModal.data.role === "BUYER_APP_ADMIN") {
        setAdminRole("Buyer Admin")
      } else {
        setAdminRole("User")
      }
    }
  }, [editAdminModal])

  async function handleAddAdmin() {
    const payload = {
      firstName: basicDetailsForm.values.firstName,
      lastName: basicDetailsForm.values.lastName,
      email: basicDetailsForm.values.email,
      phone: phone,
      role: adminRole === "Buyer Admin" ? "BUYER_APP_ADMIN" : "USER",
    }
    const response = await addAdmin(payload)
    if (response?.data?.success) {
      setAddAdminModalVisible(false)
    }
  }

  async function handleEditAdmin() {
    const id = editAdminModal.adminId
    const payload = {
      firstName: basicDetailsForm.values.firstName,
      lastName: basicDetailsForm.values.lastName,
      email: basicDetailsForm.values.email,
      phone: phone,
      role: adminRole === "Buyer Admin" ? "BUYER_APP_ADMIN" : "USER",
      // img: {
      //   profilePic: adminPhoto,
      // },
    }
    const response = await updateAdmin({ id, payload })
    if (response.data.success) {
      setEditAdminModal({
        isOpen: false,
        adminId: "",
        data: {},
      })
    }
  }

  function handleBasicDetailsSubmit(values: typeof basicDetailsForm.values) {
    handlePhoneValidation("PHONE", phone)
    if (phoneValidationText === "") {
      if (editAdminModal.isOpen) {
        handleEditAdmin()
      } else {
        handleAddAdmin()
      }
    }
  }

  function handleBasicDetailsError(error: typeof basicDetailsForm.errors) {
    handlePhoneValidation("PHONE", phone)
  }

  function handlePhoneValidation(type: string, val: string) {
    if (val?.length === 0) {
      setPhoneValidationText("Phone required")
    } else if (val?.length < 11) {
      setPhoneValidationText("Invalid phone")
    } else {
      setPhoneValidationText("")
    }
    setPhone(val)
  }

  return (
    <form
      onSubmit={basicDetailsForm.onSubmit(
        handleBasicDetailsSubmit,
        handleBasicDetailsError
      )}
    >
      <p className="txt-16 mb-20">Basic Details</p>
      {/* <Dropzone
        mediaTypes={["image/png", "image/jpeg", "image/svg+xml"]}
        setMedia={setAdminPhoto}
      /> */}
      <div className="grid grid-cols-2 col-gap-40 row-gap-25">
        <Input.Wrapper
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="FIRST NAME"
          required
        >
          <TextInput
            classNames={{
              wrapper: "clr-grey txt-12 txtw-4",
            }}
            placeholder="Enter First Name"
            variant="filled"
            {...basicDetailsForm.getInputProps("firstName")}
          />
        </Input.Wrapper>
        <Input.Wrapper
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="LAST NAME"
          required
        >
          <TextInput
            placeholder="Enter Last Name"
            variant="filled"
            {...basicDetailsForm.getInputProps("lastName")}
          />
        </Input.Wrapper>
        <Input.Wrapper
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="EMAIL"
          required
        >
          <TextInput
            placeholder="Enter Email"
            variant="filled"
            {...basicDetailsForm.getInputProps("email")}
          />
        </Input.Wrapper>

        <PhoneInputField
          label="PHONE"
          phone={phone}
          validationText={phoneValidationText}
          handlePhoneValidation={handlePhoneValidation}
        />
        <Input.Wrapper
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="AUTHORITY"
        >
          <Select
            variant="filled"
            value={adminRole}
            onChange={(val: string) => setAdminRole(val)}
            data={["Buyer Admin", "User"]}
            rightSection={<DownArrowSVG />}
          />
        </Input.Wrapper>
      </div>
      <div className="flex justify-end bd-top mt-10 pt-10">
        <Button
          type="filled-primary"
          text={`${editAdminModal.isOpen ? "Save" : "Add"}`}
          loading={adminApiInProgress}
          onClick={() => {}}
        />
      </div>
    </form>
  )
}

export default BasicDetails
