import { Divider } from "@mantine/core"
import { Header } from "components/Common/Header"
import { SearchBarContainer } from "components/Common/SearchBarContainer"
import CustomTable from "components/CustomTable/CustomTable"
import {
  useGetOrdersListQuery,
  useLazyDownloadOrdersListQuery,
} from "hooks/Admin/orderAPI"
import { useState } from "react"
import { OrderAPIOptionsType } from "types/orderManagement"
import { ecommerceColumns } from "./Columns"
import styles from "pages/OrderManagement/OrderManagement.module.scss"
import { PaginationContainer } from "components/Common/PaginationContainer"
import { EmptyPage } from "components/Common/EmptyPage"
import { NoOffersSVG } from "assets/icons"
import OptionsBarContainer from "components/Common/OptionsBarContainer/OptionsBarContainer"
import { downloadCsv } from "services/apis/admin/media"
import { useAdminTypeContext } from "context/adminType"

export default function ECommerceOrderManagement() {
  const [orderAPIOptions, setOrderAPIOptions] = useState<OrderAPIOptionsType>({
    page: 1,
    size: "10",
    search: "",
    params: "",
    sort: "",
  })

  const [downloadCSV, { data, isLoading, error }] =
    useLazyDownloadOrdersListQuery()

  const { data: ordersList } = useGetOrdersListQuery({
    page: orderAPIOptions?.page,
    size: orderAPIOptions?.size,
    search: orderAPIOptions?.search,
    params: orderAPIOptions?.params,
    sort: orderAPIOptions?.sort,
  })
  const { adminType } = useAdminTypeContext()
  const onPageChange = (value: number) =>
    setOrderAPIOptions((prev) => ({ ...prev, page: value }))

  const onRecordsSizeChange = (value: string) =>
    setOrderAPIOptions((prev) => ({ ...prev, size: value }))

  const onFiltersChange = (value: string) =>
    setOrderAPIOptions((prev) => ({
      ...prev,
      params: value,
    }))

  const onSearchChange = (value: string) => {
    console.log(value, "search")
    setOrderAPIOptions((prev) => ({
      ...prev,
      search: value,
    }))
  }
  const onSortChange = (value: string) =>
    setOrderAPIOptions((prev) => ({
      ...prev,
      sort: value,
    }))

  const handleDownloadCSV = async () => {
    downloadCsv(
      `/sub-orders/export/csv?isBusiness=${
        adminType === "e-commerce-b2b" ? true : false
      }`,
      `suborders_${adminType === "e-commerce-b2b" ? "b2b" : "b2c"}.csv`
    )
  }
  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main bg-white flex-1">
        <div className="section-header">Order Management</div>
        <div className="pg-subheader h-40 flex justify-stretch align-center mb-30 mx-80">
          <SearchBarContainer
            item={["Order", "Orders"]}
            itemsCount={ordersList?.totalCount}
            listingCount={ordersList?.data?.length}
          />
        </div>
        <Divider className="mx-80 pb-20" color="#D0D5DD" />
        <div className="mx-80 pb-20">
          <OptionsBarContainer
            placeholder="Search for orders"
            selectedTab="order"
            onFiltersChange={onFiltersChange}
            onSearchChange={onSearchChange}
            onSortChange={onSortChange}
            buttonText={isLoading ? "Downloading..." : "Download CSV"}
            onButtonClick={() => handleDownloadCSV()}
          />
        </div>
        {!!ordersList?.data?.length ? (
          <>
            <section className="min-h-70 bd-gray  flex-col bg-white mt-5 mb-5 mx-80">
              <CustomTable
                columns={ecommerceColumns}
                data={ordersList?.data}
                className={styles["ecom-table"]}
              />
            </section>
            <PaginationContainer
              page={orderAPIOptions.page}
              setPage={onPageChange}
              size={String(orderAPIOptions.size)}
              setSize={onRecordsSizeChange}
              totalEntries={ordersList.totalCount}
            />
          </>
        ) : (
          <EmptyPage img={NoOffersSVG} item="Orders" />
        )}
      </main>
    </div>
  )
}
