import { Divider, Tabs } from "@mantine/core";
import RoleStatusBadge from "components/Common/Badge/RoleStatus";
import {
  useLazyGetTicketDetailsQuery,
  useLazyCloseIssueQuery,
  useLazySendToSellerQuery,
} from "hooks/Admin/ticketAPI";
import { getDisplayDate } from "utils/helper";
import IssueDetails from "./IssueDetails";
import OrderDetails from "./OrderDetails";
import IssueTimeline from "./IssueTimeline";
import ReplyHistory from "./ReplyHistory";
import styles from "./TicketManegement.module.scss";
import { EmptyPage } from "components/Common/EmptyPage";
import { NoOrdersSVG } from "assets/icons";
import { useEffect, useState } from "react";
import RefreshSVG from "assets/icons/refresh-ccw-01.svg";
import { Button } from "components/Common/Button";
import { getStorageItem } from "services/storage";
import { useParams } from "react-router-dom";

import { Header } from "components/Common/Header";
import { useNotification } from "context";

export default function ComplaintDetails() {
  const params = useParams();
  const selectedComplaintId = params.id;
  const { setText } = useNotification();
  const [sentToSeller, setSentToSeller] = useState<boolean>(false);
  const [closedCurrentIssue, setClosedCurrentIssue] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string | null>("Issue Details");

  const [triggerGetTicketDetails, { data: complaintDetails }] =
    useLazyGetTicketDetailsQuery();

  useEffect(() => {
    if (selectedComplaintId)
      triggerGetTicketDetails({ _id: selectedComplaintId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComplaintId]);

  const {
    issue_details,
    order_details,
    timeline,
    resolution,
    comments,
    isSendToSeller,
  } = complaintDetails?.data ?? {};

  const issueDetailsProps = {
    ...issue_details,
    resolution,
  };

  const orderDetailsProps = {
    ...order_details,
  };

  const firstName = getStorageItem("firstName");
  const lastName = getStorageItem("lastName");
  const phone = getStorageItem("phone");
  const email = getStorageItem("email");

  const tabsList = [
    {
      text: "Issue Details",
      value: "Issue Details",
      component: issue_details ? (
        <IssueDetails {...issueDetailsProps} />
      ) : (
        <EmptyPage item="Issue Details" img={NoOrdersSVG} />
      ),
    },
    {
      text: "Order Details",
      value: "Order Details",
      component: order_details ? (
        <OrderDetails {...orderDetailsProps} />
      ) : (
        <EmptyPage item="Order Details" img={NoOrdersSVG} />
      ),
    },
    {
      text: "Timeline",
      value: "Timeline",
      component: timeline ? (
        <IssueTimeline timeline={timeline} />
      ) : (
        <EmptyPage item="Issue Timeline" img={NoOrdersSVG} />
      ),
    },
    {
      text: "Reply History",
      value: "Reply",
      component: comments ? (
        <ReplyHistory timeline={comments} />
      ) : (
        <EmptyPage item="No Reply Found" img={NoOrdersSVG} />
      ),
    },
  ];

  const issueDetails = [
    {
      title: "Issue ID",
      value: <p>{issue_details?.id}</p>,
    },
    {
      title: "Issue Status",
      value: (
        <span className="txt-14 txtw-5">
          <RoleStatusBadge type={String(issue_details?.status).toLowerCase()}>
            <span
              className={`marker ${
                String(issue_details?.status).toLowerCase() === "closed"
                  ? "red"
                  : "green"
              }`}
            />
          </RoleStatusBadge>
        </span>
      ),
    },
    {
      title: "Issue created on",
      value: <p>{getDisplayDate(issue_details?.ticket_created_at)}</p>,
    },
  ];

  const [triggerSendToSeller, { data: acknowledgement }] =
    useLazySendToSellerQuery();
  const [triggerIssue, { data: issueAcknowledgement }] =
    useLazyCloseIssueQuery();

  const handleSendToSeller = () => {
    if (selectedComplaintId) {
      triggerSendToSeller({
        _id: selectedComplaintId,
        body: {},
      });
    }
  };

  const handleCloseIssue = async () => {
    if (selectedComplaintId) {
      const result = await triggerIssue({
        _id: selectedComplaintId,
        body: {
          name: `${firstName} ${lastName}`,
          email: email,
          phone: `${phone}`,
        },
      });
      result && triggerGetTicketDetails({ _id: selectedComplaintId });
    }
  };

  useEffect(() => {
    if (acknowledgement?.data?.ack?.status) {
      setSentToSeller(true);
      setActiveTab("Timeline");
      setText("Sent To Seller Succesfully");
    }
  }, [acknowledgement]);

  useEffect(() => {
    if (issueAcknowledgement?.statusCode === 200) {
      setClosedCurrentIssue(true);
      setActiveTab("Timeline");
    }
  }, [issueAcknowledgement]);

  const handleRefresh = () => {
    if (selectedComplaintId) {
      triggerGetTicketDetails({ _id: selectedComplaintId });
    }
  };

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main
        className="pg-main bg-white flex-1"
        style={{ position: "relative" }}
      >
        <div className="flex mr-80 justify-stretch align-center">
          <div className="section-header" style={{ marginBottom: "30px" }}>
            Complaint Details
          </div>
        </div>

        <Divider className="mx-80 pb-20" color="#D0D5DD" />
        {selectedComplaintId && complaintDetails?.data ? (
          <>
            <div className={styles["action-buttons"]}>
              {selectedComplaintId && (
                <div className="flex gap-12">
                  <img
                    src={RefreshSVG}
                    className="img-contain bd-full px-10 brd-10 cursor-ptr"
                    onClick={handleRefresh}
                    alt="refresh-icon"
                  />
                  {!closedCurrentIssue && issue_details?.status !== "CLOSED" ? (
                    <>
                      {resolution !== undefined && (
                        <>
                          {!isSendToSeller && (
                            <>
                              <Button
                                type="outline"
                                text="Close issue"
                                onClick={handleCloseIssue}
                                classNames={{
                                  root: "pg-subheader-btn",
                                  label: "txt-14 txtw-5",
                                }}
                                className="brd-10"
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : null}

                  {!sentToSeller && issue_details?.status !== "CLOSED" ? (
                    <>
                      {!isSendToSeller && (
                        <>
                          <Button
                            type="filled-primary"
                            text="Send to seller"
                            onClick={handleSendToSeller}
                            classNames={{
                              root: "brd-10",
                              label: "txt-14 txtw-5",
                            }}
                          />
                        </>
                      )}
                    </>
                  ) : null}
                </div>
              )}
            </div>
            <div className="flex flex-col bg-white mt-24 mb-5 mx-90">
              <div className="flex justify-stretch align-center px-20">
                {issueDetails.map(({ title, value }) => (
                  <div className="flex txt-16 txtw-6 clr-grey-dark" key={title}>
                    <p>{title}:&nbsp;</p>
                    {value}
                  </div>
                ))}
              </div>
              <Divider className="my-20" color="#D0D5DD" />
              <Tabs value={activeTab} onTabChange={setActiveTab}>
                <Tabs.List>
                  {tabsList.map(({ value, text }) => (
                    <Tabs.Tab
                      key={text}
                      value={value}
                      className={styles["selected-tab"]}
                    >
                      <p className="txtw-6 txt-14">{text}</p>
                    </Tabs.Tab>
                  ))}
                </Tabs.List>
                {tabsList.map(({ value, component }) => (
                  <Tabs.Panel key={value} value={value}>
                    {component}
                  </Tabs.Panel>
                ))}
              </Tabs>
            </div>
          </>
        ) : (
          <EmptyPage img={NoOrdersSVG} item="Complaint Details" />
        )}
      </main>
    </div>
  );
}
