import { useEffect, useState } from "react"
// import { useNavigate, useSearchParams } from "react-router-dom"
import { Button } from "components/Common/Button"
import { Header } from "components/Common/Header"
import { PaginationContainer } from "components/Common/PaginationContainer"
import { EmptyPage } from "components/Common/EmptyPage"
import { SearchBarContainer } from "components/Common/SearchBarContainer"
import { AdminCard, AddAdminModal } from "components/Admins"
import { DeleteModal } from "components/Common/DeleteModal"
import { useUpdateTokens } from "utils/hooks"
import { NoOffersSVG } from "assets/icons/index"
import "./AccessManagement.scss"
import "react-phone-input-2/lib/style.css"
// import { useNotification } from "context"
// import { checkAuth } from "utils/helper"
import { ItemsHeaderRow } from "components/Common/ItemsHeaderRow"
import AdminRow from "components/Admins/AdminRow"
import {
  useDeleteAdminMutation,
  useGetAdminListQuery,
  // useUpdateAdminMutation,
} from "hooks/Admin/adminAPI"
import { Divider } from "@mantine/core"

type AdminAPIOptionsType = {
  page: number
  size: string
}

const AccessManagement = () => {
  const [adminAPIOptions, setAdminAPIOptions] = useState<AdminAPIOptionsType>({
    page: 1,
    size: "10",
  })

  const { data: adminList } = useGetAdminListQuery({
    page: adminAPIOptions?.page,
    size: adminAPIOptions?.size,
  })

  const [isAddAdminModalVisible, setAddAdminModalVisible] = useState(false)
  const [isRemoveAdminModalVisible, setRemoveAdminModalVisible] =
    useState(false)
  const [editAdminModal, setEditAdminModal] = useState({
    isOpen: false,
    adminId: "",
    data: {},
  })
  const [toBeDeletedAdmin, setToBeDeletedAdmin] = useState("")
  const [
    deleteAdmin, // This is the mutation trigger
    // { isLoading: isUpdating }, // This is the destructured mutation result
  ] = useDeleteAdminMutation()
  // const navigate = useNavigate()
  // const { setText } = useNotification()
  useUpdateTokens()

  const onPageChange = (value: number) =>
    setAdminAPIOptions((prev) => ({ ...prev, page: value }))
  const onRecordsSizeChange = (value: string) =>
    setAdminAPIOptions((prev) => ({ ...prev, size: value }))

  useEffect(() => {
    if (toBeDeletedAdmin?.length > 0) {
      setRemoveAdminModalVisible(true)
    }
  }, [toBeDeletedAdmin])

  async function handleRemoveAdmin() {
    const response = await deleteAdmin({ toBeDeletedAdmin, ...adminAPIOptions })
    if (response?.data?.success) {
      setRemoveAdminModalVisible(false)
      setToBeDeletedAdmin("")
    }
  }

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main bg-white flex-1">
        <div className="section-header">Access Management</div>
        <div className="pg-subheader h-40 flex justify-stretch align-center mb-30 mx-80">
          <SearchBarContainer
            item={["Admin", "Admins"]}
            itemsCount={adminList?.data?.count}
            listingCount={adminList?.data?.data?.length}
          />
          <Button
            type="filled-primary"
            text="Create Access"
            onClick={() => setAddAdminModalVisible(true)}
            classNames={{
              root: "pg-subheader-btn brd-10",
              label: "txt-14 txtw-5",
            }}
          />
        </div>
        <Divider className="mx-80 pb-20" color="#D0D5DD" />
        {!!adminList?.data?.data?.length ? (
          <>
            <section className="min-h-70 bd-gray flex-col bg-white my-5 mx-80">
              <ItemsHeaderRow
                rowItems={[
                  { value: "Name" },
                  { value: "Email" },
                  { value: "Phone" },
                  { value: "Role" },
                  { value: "Action" },
                ]}
              />
              {adminList?.data?.data?.map((admin: any, index: number) => (
                <AdminRow
                  key={admin.id}
                  admin={admin}
                  setToBeDeletedAdmin={setToBeDeletedAdmin}
                  setEditAdminModal={setEditAdminModal}
                  setRemoveAdminModalVisible={setRemoveAdminModalVisible}
                />
              ))}
            </section>
            <PaginationContainer
              page={adminAPIOptions.page}
              setPage={onPageChange}
              size={String(adminAPIOptions.size)}
              setSize={onRecordsSizeChange}
              totalEntries={adminList?.data?.count}
            />
          </>
        ) : (
          <EmptyPage img={NoOffersSVG} item="Access Management" />
        )}
      </main>
      <AddAdminModal
        isAddAdminModalVisible={isAddAdminModalVisible}
        setAddAdminModalVisible={setAddAdminModalVisible}
        editAdminModal={editAdminModal}
        setEditAdminModal={setEditAdminModal}
      />
      <DeleteModal
        isDeleteModalOpen={isRemoveAdminModalVisible}
        closeDeleteModal={() => setRemoveAdminModalVisible(false)}
        data={{
          text: "admin",
        }}
        onConfirm={handleRemoveAdmin}
      />
    </div>
  )
}

export default AccessManagement
