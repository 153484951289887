import { Input, Select, TextInput } from "@mantine/core";
import { Button } from "components/Common/Button";
import { useForm, yupResolver } from "@mantine/form";
import PhoneInputField from "components/Common/PhoneInput/PhoneInput";
import React, { useEffect } from "react";
import * as Yup from "yup";
import {
  useGetProfileDetailsQuery,
  useUpdateProfileDetailsMutation,
} from "hooks/Admin/profileDetailsAPI";
import { useNotification } from "context";

type profiledetailsProps = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      /^[A-Za-z]+(?: [A-Za-z]+)*$/,
      "First name should only contain letters and spaces (no leading or trailing spaces)"
    )
    .required("First name is required"),
  lastName: Yup.string()
    .matches(
      /^[A-Za-z]+(?: [A-Za-z]+)*$/,
      "Last name should only contain letters and spaces (no leading or trailing spaces)"
    )
    .required("Last name is required"),
  email: Yup.string().email().required("Invalid email"),
  mobile: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
});

function EditProfile() {
  const profileForm = useForm({
    validateInputOnChange: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
    },
    validate: yupResolver(schema),
  });

  const [updateProfileDetails] = useUpdateProfileDetailsMutation();
  const { setText } = useNotification();
  const { data: profileDetails } = useGetProfileDetailsQuery();

  useEffect(() => {
    const profileData = profileDetails?.data?.user;
    if (profileData) {
      profileForm.setValues({
        firstName: profileData?.firstName || "",
        lastName: profileData?.lastName || "",
        email: profileData?.email,
        mobile: profileData?.phone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileDetails]);

  async function handleEditProfileDetails() {
    let payload: profiledetailsProps = {
      firstName: profileForm.values.firstName,
      lastName: profileForm.values.lastName,
      email: profileForm.values.email,
      phone: profileForm.values.mobile,
    };

    const response = await updateProfileDetails({
      payload,
    });
    if (response?.data?.success) {
      // console.log("EDITED");
      setText("Profile Details Updated");
      // window.location.reload();
    }
  }

  const onSubmit = () => {
    if (profileDetails?.data) {
      handleEditProfileDetails();
    }
  };

  return (
    <form onSubmit={profileForm.onSubmit(onSubmit)} className="px-3">
      <Input.Wrapper
        classNames={{
          label: "clr-grey txt-12 txtw-4",
        }}
        label="First Name"
        required
      >
        <TextInput
          placeholder="First Name"
          variant="filled"
          {...profileForm.getInputProps("firstName")}
        />
      </Input.Wrapper>

      <Input.Wrapper
        classNames={{
          label: "clr-grey txt-12 txtw-4",
        }}
        label="Last Name"
        required
      >
        <TextInput
          placeholder="Last Name"
          variant="filled"
          {...profileForm.getInputProps("lastName")}
        />
      </Input.Wrapper>

      <Input.Wrapper
        classNames={{
          label: "clr-grey txt-12 txtw-4",
        }}
        label="Email"
        required
      >
        <TextInput
          placeholder="Enter Email"
          variant="filled"
          {...profileForm.getInputProps("email")}
        />
      </Input.Wrapper>
      <Input.Wrapper
        classNames={{
          label: "clr-grey txt-12 txtw-4",
        }}
        label="Mobile Number"
        required
      >
        <TextInput
          placeholder="Enter Phone no."
          variant="filled"
          {...profileForm.getInputProps("mobile")}
        />
      </Input.Wrapper>
      <PhoneInputField
        label="PHONE"
        phone={profileForm.getInputProps("mobile").value}
        validationText={profileForm.getInputProps("mobile").error}
        handlePhoneValidation={profileForm.getInputProps("mobile").onChange}
      />
      {/* <Select
        required
        data={[
          {
            value: "121",
            label: "Super Admin",
          },
          {
            value: "122",
            label: "Admin",
          },
          {
            value: "123",
            label: "Sub Admin",
          },
        ]}
        placeholder="Pick one"
        label="delivery charge"
        classNames={{
          label: "clr-grey txt-12 txtw-4 txt-ucase",
        }}
        {...profileForm.getInputProps("role")}
      /> */}
      <div className="flex justify-start bd-top mt-10 pt-10">
        <Button
          type="filled-primary"
          text="Update Profile"
          onClick={() => {}}
        />
      </div>
    </form>
  );
}

export default EditProfile;
