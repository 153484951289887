import { useEffect, useContext } from "react";
import { AppRouter } from "./router";
import axios from "axios";
import { AppContext } from "context/userContext";

const App = () => {
  const { setSpinner } = useContext(AppContext);

  const getStorageItem = (key: any) => {
    return localStorage.getItem(key);
  };

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        if (!config.headers) {
          config.headers = {};
        }
        setSpinner(true);

        const token = getStorageItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      },
      (error) => {
        setSpinner(false);
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        setSpinner(false);
        return response;
      },
      (error) => {
        setSpinner(false);
        if (error.response && error.response.status === 401) {
          window.location.href = "/login";
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [setSpinner]);

  return <AppRouter />;
};

export default App;
