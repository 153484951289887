import { useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { ActionIcon } from "@mantine/core"
import { OpenStatusBadge } from "components/Common/Badge"
import { Button } from "components/Common/Button"
import { Header } from "components/Common/Header"
import {
  BrandDetailsPanel,
  AssociatedOutlets,
  AddBrandModal,
} from "components/Brands"
import { AssociatedOffers } from "components/Common/AssociatedOffers"
import { getBrand } from "services/apis/admin"
import { checkAuth, isOutletOpen } from "utils/helper"
import { useBrand, useNotification } from "context"
import {
  LeftArrowBlackSVG as LeftArrowBlack,
  OrdersBoxGraySVG,
  PencilIconSVG,
} from "assets/icons/index"
import "./Brand.scss"
import { useUpdateTokens } from "utils/hooks"

const Brand = () => {
  const { brand, setBrand, editBrandModal, setEditBrandModal } = useBrand()
  const params = useParams()
  const navigate = useNavigate()
  const { setText } = useNotification()
  useUpdateTokens()

  useEffect(() => {
    ;(async () => {
      if (!editBrandModal.isOpen) {
        checkAuth(
          async () => {
            const response = await getBrand(params?.brandId)
            if (response.success) {
              setBrand(response.data)
            }
          },
          setText,
          navigate
        )
      }
    })()
  }, [editBrandModal])

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main flex-1">
        <div className="pg-subheader h-40 flex justify-stretch align-center mt-30 mb-30 mx-80">
          <div className="flex flex-col">
            <div className="flex mb-5">
              <ActionIcon component={Link} to="/brands">
                <LeftArrowBlack />
              </ActionIcon>
              <p className="txt-20 ml-5 txtw-6">{brand?.name}</p>
            </div>
            <div className="flex align-center ml-5">
              <OrdersBoxGraySVG />
              <p className="txt-12 txtw-6 txt-ucase clr-grey pl-10 pr-10 outlet-count mr-8">
                {brand?.outlets.length} outlets
              </p>
              <OpenStatusBadge
                type={isOutletOpen(
                  brand?.openingTime ?? "",
                  brand?.closingTime ?? ""
                )}
              />
            </div>
          </div>
          <Button
            leftIcon={<PencilIconSVG />}
            type="filled-secondary"
            text="Edit"
            onClick={() => {
              setEditBrandModal({
                isOpen: true,
                brandId: params?.brandId ?? "",
              })
            }}
          />
        </div>
        <section className="flex">
          <BrandDetailsPanel />
          <section className="mr-80 ml-20 flex-1">
            <AssociatedOffers offers={brand?.offers ?? []} type="brand" />
            <AssociatedOutlets outlets={brand?.outlets ?? []} />
          </section>
        </section>
      </main>
      <AddBrandModal
        editBrandModal={editBrandModal}
        setEditBrandModal={setEditBrandModal}
      />
    </div>
  )
}

export default Brand
