import { TextInput } from "@mantine/core"
import { useDebouncedValue } from "@mantine/hooks"
import { MagnifyingGlassSVG } from "assets/icons/index"

import { useEffect, useState } from "react"
import { SortByIcon, FiltersIcon } from "assets/icons/index"
import { Button } from "components/Common/Button"
import { useFilters, useSortBy } from "context"
import Filters from "components/Filters/Filters"
import SortBy from "components/Sortby/Sortby"
import { getStorageItem } from "services/storage"

interface searchBarContainerProps {
  placeholder?: string
  selectedTab: string
  onFiltersChange?: (value: any) => void
  onSearchChange?: (value: any) => void
  onSortChange?: (value: any) => void
  isSort?: boolean
  isFilter?: boolean
  isSearch?: boolean
  onButtonClick?: (value: any) => void
  buttonText?: string
}

const OptionsBarContainer = ({
  placeholder,
  selectedTab,
  onFiltersChange = () => {},
  onSearchChange = () => {},
  onSortChange = () => {},
  onButtonClick = () => {},
  buttonText = "",
  isSort = true,
  isFilter = true,
  isSearch = true,
}: searchBarContainerProps) => {
  const [searchBarValue, setSearchBarValue] = useState<any>(null)
  const [debouncedSearchBarValue] = useDebouncedValue(searchBarValue, 400)
  const { isFiltersModalVisible, setIsFiltersModalVisible } = useFilters()
  const { isSortByModalVisible, setIsSortByModalVisible } = useSortBy()
  const [filterCount, setFilterCount] = useState(0)
  const [sortCount, setSortCount] = useState(0)

  useEffect(() => {
    const defaultSort = getStorageItem("sortBy")
    const defaultFilters = getStorageItem("filters")
    if (selectedTab) {
      if (defaultFilters && defaultFilters[selectedTab]) {
        let count: any = Object.values(defaultFilters[selectedTab]).reduce(
          (accumulator, currentArray: any) => {
            // Check if the currentArray has values and they are not empty
            if (currentArray.length > 0) {
              // Increment the accumulator by the number of values in the currentArray
              accumulator += currentArray.length
            }
            return accumulator
          },
          0
        )
        setFilterCount(count)
      }
      if (defaultSort && defaultSort[selectedTab]) {
        setSortCount(Object.keys(defaultSort[selectedTab])?.length)
      }
    } else {
      setFilterCount(0)
      setSortCount(0)
    }
    // eslint-disable-next-line
  }, [selectedTab])

  useEffect(() => {
    if (debouncedSearchBarValue !== null && isSearch) {
      onSearchChange(debouncedSearchBarValue)
    }
    // eslint-disable-next-line
  }, [debouncedSearchBarValue])

  return (
    <div className="search-container flex justify-stretch">
      {isSearch && (
        <TextInput
          value={searchBarValue}
          onChange={(e) => setSearchBarValue(e.currentTarget.value)}
          placeholder={placeholder}
          icon={<MagnifyingGlassSVG />}
          classNames={{
            wrapper: "w-300",
            input: "search-input",
          }}
        />
      )}
      <div className="flex flex-row items-center">
        {" "}
        {isSort && (
          <Button
            leftIcon={<SortByIcon />}
            type="default"
            className="mx-10"
            text={
              <p>
                <span>Sort By </span>
                {sortCount > 0 && (
                  <span className="clr-primary">{sortCount}</span>
                )}{" "}
              </p>
            }
            onClick={() => setIsSortByModalVisible(true)}
          />
        )}
        {isFilter && (
          <Button
            leftIcon={<FiltersIcon />}
            type="default"
            text={
              <p>
                <span>Filters </span>

                {filterCount > 0 && (
                  <span className="clr-primary">{filterCount}</span>
                )}
              </p>
            }
            onClick={() => setIsFiltersModalVisible(true)}
          />
        )}
        {buttonText !== "" && (
          <div style={{ marginLeft: "10px" }}>
            <Button
              type="filled-primary"
              text={buttonText}
              onClick={onButtonClick}
              classNames={{
                root: "pg-subheader-btn brd-10",
                label: "txt-14 txtw-5",
              }}
            />
          </div>
        )}
      </div>
      {isFiltersModalVisible && (
        <div>
          <Filters
            isFiltersModalVisible={isFiltersModalVisible}
            type={selectedTab}
            onFiltersChange={onFiltersChange}
            setFilterCount={setFilterCount}
          />
        </div>
      )}
      {isSortByModalVisible && (
        <div>
          <SortBy
            isSortByModalVisible={isSortByModalVisible}
            type={selectedTab}
            onSortChange={onSortChange}
            setSortCount={setSortCount}
          />
        </div>
      )}
    </div>
  )
}

export default OptionsBarContainer
