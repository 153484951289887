// Need to use the React-specific entry point to allow generating React hooks
import { createApi } from "@reduxjs/toolkit/query/react"
import { axiosBaseQuery, mobilityAPIKey } from "hooks/baseQuery"
import { mobilityRidesBaseURL } from "services/url"
import { PayloadType } from "types/common"

export const mobilityCustomerAPI = createApi({
  reducerPath: "mobilityCustomerAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: String(mobilityRidesBaseURL),
    apiKey: mobilityAPIKey,
  }),
  endpoints: (builder) => ({
    getRidesListByCustomerID: builder.query({
      query: ({ id, page, size }: PayloadType) => {
        const searchParams = new URLSearchParams()
        if (page) searchParams.set("page", String(page))
        if (size) searchParams.set("size", String(size))
        return {
          method: "GET",
          url: `/mobility/rider/admin/ridesByCustomer/${id}`,
        }
      },
    }),
  }),
})

export const { useGetRidesListByCustomerIDQuery } = mobilityCustomerAPI
