import { Header } from "components/Common/Header"
import CustomTable from "components/CustomTable/CustomTable"
import { useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { CustomerAPIOptionsType } from "types/customerManagement"
import { capitalize, padDecimals, rideStatusMap } from "utils/helper"
import { mobilityColumns } from "./Columns"
import { PaginationContainer } from "components/Common/PaginationContainer"
import { EmptyPage } from "components/Common/EmptyPage"
import { LeftArrowSVG, NoOffersSVG } from "assets/icons"
import styles from "pages/Customer/Customer.module.scss"
import { useGetRidesListByCustomerIDQuery } from "hooks/Mobility/customerAPI"
import { Button } from "components/Common/Button"

export default function MobilityCustomers() {
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const goBack = () => {
    navigate(`/customers?page=${location.state}`)
  }

  const [customerAPIOptions, setCustomerAPIOptions] =
    useState<CustomerAPIOptionsType>({
      page: 1,
      size: "10",
    })

  const { data: customerRidesDetails } = useGetRidesListByCustomerIDQuery({
    id: params?.id,
    page: customerAPIOptions?.page,
    size: customerAPIOptions?.size?.toString(),
  })

  const headerDetails = [
    customerRidesDetails?.result?.totalRides && {
      text: "Total Rides",
      value: customerRidesDetails?.result?.totalRides,
    },
    customerRidesDetails?.result?.totalRidePay && {
      text: "Total Amount",
      value: "₹" + customerRidesDetails?.result?.totalRidePay.toFixed(2),
    },
  ].filter((item) => item)

  const onPageChange = (value: number) =>
    setCustomerAPIOptions((prev) => ({ ...prev, page: value }))

  const onRecordsSizeChange = (value: string) =>
    setCustomerAPIOptions((prev) => ({ ...prev, size: value }))

  const rides =
    !!customerRidesDetails?.result?.formattedRides?.length &&
    customerRidesDetails?.result?.formattedRides

  const tableData =
    rides?.length > 0 &&
    rides.map((ride: any) => {
      return (
        ride && {
          customerOrderId: ride._id,
          driverName: ride.driver?.name,
          vehicleNumber: ride.vehicle?.number,
          vehicle:
            ride.make && ride.model
              ? `${ride.make} ${ride.model}`
              : ride.make
              ? ride.make
              : ride.model
              ? ride.model
              : "",
          pickupLocation: ride.start,
          dropoffLocation: ride.end,
          price: ride.ridePay,
          payment: String(ride.paymentMode).toUpperCase(),
          status: capitalize(rideStatusMap(ride.rideStatus).toLowerCase()),
        }
      )
    })

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main bg-white flex-1">
        <div className="section-header">
          <Button
            type="subtle"
            text="Back"
            onClick={goBack}
            className="align-self-start"
            leftIcon={<LeftArrowSVG />}
          />
          <br />
          <span className="txtw-6 txt-30">
            {rides[0]?.rider?.name || "N/A"}
          </span>
          <div className="flex w-full flex-wrap justify-stretch gap-20 my-30">
            {headerDetails.map(({ text, value }) => (
              <div
                className="flex flex-1 bd-full brd-8 bg-white px-24 py-20"
                key={text}
              >
                <div className="flex flex-col">
                  <span className="txt-14 clr-grey txtw-5">{text}</span>
                  <span className="txtw-6 txt-20 mt-8 mb-16">{value}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col mb-20">
            <span className="txtw-6 txt-18">Rides</span>
            <span className="txtw-4 txt-14 clr-grey">
              {`${customerRidesDetails?.result?.totalRides} ride${
                customerRidesDetails?.result?.totalRides === 1 ? "" : "s"
              }`}
            </span>
          </div>
        </div>

        {tableData && tableData.length > 0 ? (
          <>
            <section className="min-h-70 bd-gray  flex-col bg-white mt-5 mb-5 mx-80">
              <CustomTable
                columns={mobilityColumns}
                data={tableData}
                className={styles["mobility-table"]}
              />
            </section>
            <PaginationContainer
              page={customerAPIOptions.page}
              setPage={onPageChange}
              size={String(customerAPIOptions.size)}
              setSize={onRecordsSizeChange}
              totalEntries={customerRidesDetails?.result?.totalRides}
            />
          </>
        ) : (
          <EmptyPage img={NoOffersSVG} item="Customer details" />
        )}
      </main>
    </div>
  )
}
