import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ItemsHeaderRow } from "components/Common/ItemsHeaderRow"
import { PaginationContainer } from "components/Common/PaginationContainer"
import { SearchBarContainer } from "components/Common/SearchBarContainer"
import { Button } from "components/Common/Button"
import { Header } from "components/Common/Header"
import { BrandRow, AddBrandModal } from "components/Brands"
import { EmptyPage } from "components/Common/EmptyPage"
import { DeleteModal } from "components/Common/DeleteModal"
import { deleteBrand, getBrands } from "services/apis/admin"
import { brandType } from "types/brand"
import { useUpdateSearchParams, useUpdateTokens } from "utils/hooks"
import { useNotification, useBrands } from "context"
import { SlidersSVG as Sliders } from "assets/icons/index"
import NoBrandsPNG from "assets/images/NoBrands.png"
import "./Brands.scss"
import { checkAuth } from "utils/helper"

const Brands = () => {
  const {
    isAddBrandModalOpen,
    setIsAddBrandModalOpen,
    deleteBrandModal,
    setDeleteBrandModal,
    editBrandModal,
    setEditBrandModal,
    brandsList,
    setBrandsList,
    brandsPagination,
    setBrandsPagination,
  } = useBrands()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { setText } = useNotification()
  useUpdateSearchParams(
    brandsPagination.page,
    brandsPagination.size,
    setSearchParams
  )
  useUpdateTokens()

  useEffect(() => {
    ;(async () => {
      if (
        !isAddBrandModalOpen &&
        !deleteBrandModal.isOpen &&
        !editBrandModal.isOpen &&
        searchParams.get("page") &&
        searchParams.get("size")
      ) {
        checkAuth(
          async () => {
            const response = await getBrands(
              searchParams.get("page"),
              searchParams.get("size")
            )
            if (response.success) {
              setBrandsList([...response.data.brands])
              setBrandsPagination((p: any) => ({
                ...p,
                totalEntries: response.data.count,
              }))
            }
          },
          setText,
          navigate
        )
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams,
    isAddBrandModalOpen,
    deleteBrandModal.isOpen,
    editBrandModal.isOpen,
  ])

  async function handleDeleteBrand() {
    const response = await deleteBrand(deleteBrandModal.brandId)
    if (response?.status === 204) {
      setDeleteBrandModal({
        isOpen: false,
        brandId: "",
      })
    }
  }

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main flex-1">
        <div className="pg-subheader h-40 flex justify-stretch align-center mt-30 mb-30 mx-80">
          <SearchBarContainer
            item={["Brand", "Brands"]}
            itemsCount={brandsList?.length}
            listingCount={brandsList?.length}
          />
          <div className="action-container flex align-center">
            {/* <Button
                            leftIcon={<Sliders />}
                            type="filled-secondary"
                            text="Filters"
                            onClick={() => {}}
                        /> */}
            <Button
              type="filled-primary"
              text="+ Add a brand"
              onClick={() => setIsAddBrandModalOpen(true)}
            />
          </div>
        </div>
        {brandsList.length > 0 ? (
          <>
            <section className="bg-white min-h-70 mr-80 mb-10 ml-80 flex flex-col brd-10">
              <ItemsHeaderRow
                rowItems={[
                  { value: "name" },
                  // { value: "cuisines" },
                  {
                    value: "avg. rating (out of 5)",
                    sort: true,
                  },
                  { value: "status" },
                  { value: "actions" },
                  { value: "active" },
                ]}
              />
              {brandsList?.map((brand: brandType) => (
                <BrandRow key={brand._id} brand={brand} />
              ))}
            </section>
            <PaginationContainer
              page={brandsPagination.page}
              setPage={(page: number) => {
                setBrandsPagination((p: any) => ({
                  ...p,
                  page,
                }))
              }}
              size={brandsPagination.size}
              setSize={(size: string) => {
                setBrandsPagination((p: any) => ({
                  ...p,
                  size,
                }))
              }}
              totalEntries={brandsPagination.totalEntries}
            />
          </>
        ) : (
          <EmptyPage img={NoBrandsPNG} item="Brands" />
        )}
      </main>
      <AddBrandModal
        editBrandModal={editBrandModal}
        setEditBrandModal={setEditBrandModal}
      />
      <DeleteModal
        onConfirm={handleDeleteBrand}
        isDeleteModalOpen={deleteBrandModal.isOpen}
        closeDeleteModal={() => {
          setDeleteBrandModal({
            brandId: "",
            isOpen: false,
          })
        }}
        data={{ text: "brand" }}
      />
    </div>
  )
}

export default Brands
