import { ActionIcon } from "@mantine/core"
import { PencilIconSVG, TrashboxIconSVG } from "assets/icons/index"

import { adminType } from "types/admin"
import { getDisplayDate } from "utils/helper"

const AdminRow = ({
  admin,
  setToBeDeletedAdmin,
  setEditAdminModal,
  setRemoveAdminModalVisible,
}: {
  admin: adminType
  setToBeDeletedAdmin: (toBeDeletedAdmin: string) => void
  setEditAdminModal: Function
  setRemoveAdminModalVisible: Function
}) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        // navigate(`/outlets/${outlet._id}`)
      }}
      className="grid grid-cols-5 grid-rowh-75 bd-btm cursor-ptr"
    >
      <div className="flex flex-col align-start justify-center pl-30 pr-30">
        <p className="txt-14 txtw-5">
          {admin?.firstName} {admin?.lastName}
        </p>
      </div>
      <div
        className="flex flex-col align-start justify-center pl-30 pr-30"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <p className="txt-14 txtw-5">{admin?.email}</p>
      </div>
      <div className="flex  align-center justify-end pl-30 pr-30">
        <p className="txt-14 txtw-5">{admin?.phone}</p>
      </div>

      <div className="flex  align-center justify-end pl-30 pr-30 txt-12">
        {admin.role === "BUYER_APP_ADMIN"
          ? "Buyer App Admin"
          : admin.role === "USER"
          ? "User"
          : admin.role === "BUYER_APP_SUPER_ADMIN"
          ? "Buyer App Super Admin"
          : "Protean Super Admin"}
      </div>
      {/* <div
        onClick={(e) => {
          e.stopPropagation()
        }}
        className="flex  align-center justify-end pl-30 pr-30"
      > */}
      {/* <Switch
          checked={false}
          color="primary"
          classNames={{
            track: "cursor-ptr",
          }}
        /> */}
      {/* </div> */}
      <div className="flex align-center justify-end pl-30 pr-30">
        <div
          onClick={(e) => {
            e.stopPropagation()
            // setEditOutletModal({
            //     isOpen: true,
            //     outletId: outlet._id,
            // })
          }}
        >
          <ActionIcon
            style={{
              marginRight: "8px",
            }}
            onClick={() =>
              setEditAdminModal({
                isOpen: true,
                adminId: admin._id,
                data: admin,
              })
            }
          >
            <PencilIconSVG />
          </ActionIcon>
        </div>
        <div
          onClick={() => {
            setToBeDeletedAdmin(admin._id)
            setRemoveAdminModalVisible(true)
          }}
        >
          <ActionIcon>
            <TrashboxIconSVG />
          </ActionIcon>
        </div>
      </div>
    </div>
  )
}
export default AdminRow
