import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextInput } from "@mantine/core";
import OTPInput from "components/Common/OTPInput/OTPInput";
import { Button } from "components/Common/Button";
import { setStorageItem } from "services/storage";
import LoginBg from "assets/images/LoginBg.svg";
import { useTokenInterval } from "context/tokenInterval";
import { useNotification } from "context";
// import { checkAuth } from "utils/helper"
import { EnvelopePrimarySVG, LeftArrowSVG } from "assets/icons";
import "./Login.scss";
import SpiceSmartLogo from "assets/images/spiceSmartLogo.png";
import { getOtp, loginUser } from "services/apis/user/login";

const Login = () => {
  const [isOTPInputVisible, setIsOTPInputVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [loginApiInProgress, setLoginApiInProgress] = useState(false);
  const navigate = useNavigate();
  const { setId } = useTokenInterval();
  const { setText } = useNotification();

  async function handleEmailSubmit() {
    if (isOTPInputVisible) {
      try {
        setLoginApiInProgress(true);
        const response = await loginUser(email, otp);
        setLoginApiInProgress(false);
        if (response.success) {
          setStorageItem("token", response.data.token);
          setStorageItem("role", response.data.role);
          setStorageItem("email", response.data.user.email);
          setStorageItem("firstName", response.data.user.firstName || "");
          setStorageItem("lastName", response.data.user.lastName || "");
          setStorageItem("phone", response.data.user.phone || "");
          navigate("/hub");
        }
      } catch (e: any) {
        setText(e?.response?.data?.error ?? "");
      } finally {
        setLoginApiInProgress(false);
      }
    } else {
      try {
        setLoginApiInProgress(true);
        setIsOTPInputVisible(true);
        const response = await getOtp(email);
        if (response.success) {
          setIsOTPInputVisible(true);
        }
      } catch (e: any) {
        setIsOTPInputVisible(false);
        setText(e?.response?.data?.error ?? "");
      } finally {
        setLoginApiInProgress(false);
      }
    }
  }

  return (
    <div className="login-pg-container flex">
      <main className="login-main flex flex-col align-start justify-stretch flex-1">
        <div className="flex">
          <img src={SpiceSmartLogo} alt="logo" className="h-80 mr-5" />
        </div>

        <div
          style={{
            width: "100%",
          }}
        >
          {isOTPInputVisible ? (
            <>
              <p className="txt-34 txtw-7 txt-cap">Verify OTP</p>
              <p className="txt-20 txt-4 mb-50">
                A 4 digit OTP is sent on {email}
              </p>
              <OTPInput setOtp={setOtp} handleEmailSubmit={handleEmailSubmit} />
            </>
          ) : (
            <>
              <p className="txt-34 txtw-7 txt-cap">welcome</p>
              <p className="txt-20 txt-4 mb-50">
                Please login to your Spice Smart Shop
                <br />
                admin account
              </p>
              <TextInput
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleEmailSubmit();
                  }
                }}
                classNames={{
                  wrapper: "input-wrapper",
                  icon: "input-icon",
                  input: "input-label txt-16 brd-10",
                }}
                icon={<EnvelopePrimarySVG />}
                variant="filled"
                placeholder="Enter your e-mail address"
              />
            </>
          )}
          <Button
            type="filled-primary"
            text="Continue"
            loading={loginApiInProgress}
            onClick={handleEmailSubmit}
            classNames={{
              root: "continue-btn mt-30 brd-10",
              label: "txt-16",
            }}
          />
        </div>
        {isOTPInputVisible ? (
          <div className="back-btn-container flex justify-center mt-50 mb-30">
            <Button
              type="subtle"
              text="Go back"
              onClick={() => setIsOTPInputVisible(false)}
              leftIcon={<LeftArrowSVG />}
              classNames={{
                root: "back-btn",
              }}
            />
          </div>
        ) : (
          <div className="empty-div"></div>
        )}
      </main>
      <img className="hero-img object" src={LoginBg} alt="indian meal" />
    </div>
  );
};

export default Login;
