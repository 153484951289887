import { configureStore } from "@reduxjs/toolkit"
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from "@reduxjs/toolkit/query"
import { adminAPI } from "hooks/Admin/adminAPI"
import { categoryAPI } from "hooks/Admin/categoryAPI"
import { ecommerceCustomerAPI } from "hooks/Admin/customerAPI"
import { mobilityCustomerAPI } from "hooks/Mobility/customerAPI"
import { offerAPI } from "hooks/Admin/offersAPI"
import { ecommerceOrderAPI } from "hooks/Admin/orderAPI"
import { subCategoryAPI } from "hooks/Admin/subCategoryAPI"
import { ticketAPI } from "hooks/Admin/ticketAPI"
import { dashboardAPI } from "hooks/Mobility/dashboardAPI"
import { mobilityOrderAPI } from "hooks/Mobility/orderAPI"
import { buyerAppInfoAPI } from "hooks/Admin/buyerAppInfo"
import { bankDetailsAPI } from "hooks/Admin/bankDetailsAPI"
import { profileDetailsAPI } from "hooks/Admin/profileDetailsAPI"
import { adminDashboardAPI } from "hooks/Admin/adminDashboardAPI"

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [adminAPI.reducerPath]: adminAPI.reducer,
    [ecommerceCustomerAPI.reducerPath]: ecommerceCustomerAPI.reducer,
    [mobilityCustomerAPI.reducerPath]: mobilityCustomerAPI.reducer,
    [ecommerceOrderAPI.reducerPath]: ecommerceOrderAPI.reducer,
    [mobilityOrderAPI.reducerPath]: mobilityOrderAPI.reducer,
    [categoryAPI.reducerPath]: categoryAPI.reducer,
    [subCategoryAPI.reducerPath]: subCategoryAPI.reducer,
    [offerAPI.reducerPath]: offerAPI.reducer,
    [ticketAPI.reducerPath]: ticketAPI.reducer,
    [dashboardAPI.reducerPath]: dashboardAPI.reducer,
    [buyerAppInfoAPI.reducerPath]: buyerAppInfoAPI.reducer,
    [bankDetailsAPI.reducerPath]: bankDetailsAPI.reducer,
    [profileDetailsAPI.reducerPath]: profileDetailsAPI.reducer,
    [adminDashboardAPI.reducerPath]: adminDashboardAPI.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      adminAPI.middleware,
      ecommerceCustomerAPI.middleware,
      mobilityCustomerAPI.middleware,
      ecommerceOrderAPI.middleware,
      mobilityOrderAPI.middleware,
      categoryAPI.middleware,
      subCategoryAPI.middleware,
      offerAPI.middleware,
      ticketAPI.middleware,
      dashboardAPI.middleware,
      buyerAppInfoAPI.middleware,
      bankDetailsAPI.middleware,
      profileDetailsAPI.middleware,
      adminDashboardAPI.middleware,
    ]),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
