import { BaseQueryFn } from "@reduxjs/toolkit/query"
import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { getStorageItem } from "services/storage"


const adminType = getStorageItem("adminType")
let searchParams: any = null

export const axiosBaseQuery =
  (
    { baseUrl, apiKey }: { baseUrl: string; apiKey?: string } = {
      baseUrl: "",
    }
  ): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig["method"]
      data?: AxiosRequestConfig["data"]
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data }) => {
    const token = getStorageItem("token")
    if (adminType === "e-commerce-b2b") {
      searchParams = new URLSearchParams()
      searchParams.set("isBusiness", String(true))
    }
    try {
      console.log(url)
      const result = await axios({
        url:
          searchParams && method === "GET"
            ? baseUrl +
              url +
              (url?.includes("?") ? `&${searchParams}` : `?${searchParams}`)
            : baseUrl + url,
        headers: {
          ...(token ? { authorization: token } : {}),
          ...(apiKey ? { apiKey } : {}),
          'ngrok-skip-browser-warning': '69420'
        },
        method,
        data,
      })
      return { data: result.data }
    } catch (axiosError) {
      let err = axiosError as AxiosError
      return {
        error: { status: err.response?.status, data: err.response?.data },
      }
    }
  }

export const mobilityAPIKey = process.env.REACT_APP_MOBILITY_API_KEY
  ? String(process.env.REACT_APP_MOBILITY_API_KEY)
  : "9346dc4594114fd9eb32e4eb99b306f6601afdcc5524e7a0d78b60ceed376438"
