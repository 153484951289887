import axios from "axios"
import { getStorageItem } from "services/storage"
import { userAppBaseURL } from "services/url"

export const getAdminList = async (page: number, size: string) => {
  const searchParams = new URLSearchParams()
  searchParams.set("page", String(page))
  searchParams.set("size", String(size))
  const response = await axios({
    method: "GET",
    url: userAppBaseURL + "/user",
    params: searchParams,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return response.data
}
export const getAdmin = async (adminId: string) => {
  const response = await axios({
    method: "GET",
    url: `${userAppBaseURL}/user/${adminId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}

export const editAdmin = async (adminId: string, adminData: any) => {
  const response = await axios({
    method: "PATCH",
    url: `${userAppBaseURL}/${adminId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      ...adminData,
    },
  })
  return await response.data
}

export const deleteAdmin = async (adminId: string) => {
  const response = await axios({
    method: "DELETE",
    url: `${userAppBaseURL}/user/${adminId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response
}