import { Avatar, Divider } from "@mantine/core"
import { Link } from "react-router-dom"

type Props = {
  data: {
    img?: string
    name: string
    category: string
    quantity?: string
  }[]
}

function Listing({ data }: Props) {
  return (
    <div className="py-10 w-full flex flex-col justify-stretch">
      {data &&
        data?.map((item, index) => (
          <div key={index} className="w-full flex flex-col">
            <div className="flex justify-stretch align-center">
              <div className="flex align-center">
                {item.img ? (
                  <Avatar
                    src={item.img}
                    alt={item.name}
                    radius="xl"
                    size="lg"
                  />
                ) : null}
                <div className="ml-10">
                  <div className="txtw-6 txt-16">{item.name}</div>
                  <div className="txtw-4 txt-14">{item.category}</div>
                </div>
              </div>
              {item.quantity && (
                <div className="txtw-5 txt-16 align-end clr-green">
                  {item.quantity}
                </div>
              )}
            </div>
            {index < data.length - 1 && (
              <Divider style={{ margin: "10px 0" }} />
            )}
          </div>
        ))}
      {/* <Link
        to="/top-stores"
        className="mt-16 txt-14 txtw-6 clr-primary no-underline align-self-end w-fit-content"
      >
        Show More
      </Link> */}
    </div>
  )
}

export default Listing
