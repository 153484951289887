import axios from "axios"
import { getStorageItem } from "services/storage"
import { userAppBaseURL } from "services/url"

const mediaUploadUrl = `${userAppBaseURL}/media`

export const uploadMedia = async (file: File) => {
  let bodyFormData = new FormData()
  bodyFormData.append("media", file)
  const response = await axios({
    method: "POST",
    url: mediaUploadUrl,
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}

export const downloadCsv = async (
  url: String,
  name: string = "downloadble.csv"
) => {
  const baseURL = `${userAppBaseURL}${url}`
  try {

    const response = await axios.get(baseURL, {
      responseType: "blob",
    })

    const blob = new Blob([response.data], { type: "text/csv" })
    const link = document.createElement("a")
    link.href = window.URL.createObjectURL(blob)
    let fileName = name
    link.download = fileName
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
  } catch (error) {
    console.error("Error downloading the CSV file:", error)
  }
}
