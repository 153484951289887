import { Divider, Input, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { NoOrdersSVG } from "assets/icons";
import RoleStatusBadge from "components/Common/Badge/RoleStatus";
import { Button } from "components/Common/Button";
import { Dropzone } from "components/Common/Dropzone";
import { EmptyPage } from "components/Common/EmptyPage";
import ImagePreview from "components/Common/ImagePreview/ImagePreview";
import { useNotification } from "context";
import {
  useLazyGetTicketDetailsQuery,
  useLazySendReplyQuery,
} from "hooks/Admin/ticketAPI";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { IssueDetailsProps } from "types/ticket";
import { getDisplayDate, getDisplayTime } from "utils/helper";

type DetailsType = { value: string; text: string };

export default function IssueDetails({
  title,
  image,
  description,
  category,
  last_action,
  sub_category,
  long_desc,
  short_desc,
  resolution,
  ticket_created_at,
  ticket_updated_at,
  id,
}: IssueDetailsProps) {
  const [issueImage, setIssueImage] = useState(null);
  const [triggerSendComments] = useLazySendReplyQuery();
  const { setText } = useNotification();
  const takeActionForm = useForm<any>({
    initialValues: {
      description: "",
    },
  });

  const imagesList =
    image && image?.length > 0
      ? image.filter((img: string | null) => img)
      : null;

  async function handlereply() {
    const payload = {
      issueId: id,
      ...takeActionForm.values,
      ...((issueImage ?? [])?.length && { files: issueImage }),
    };
    const response = await triggerSendComments({
      body: payload,
    });
    if (response?.data?.success) {
      setText("Reply Sent Successfully");
      takeActionForm.reset();
      setIssueImage(null);
      handleRefresh();
    }
  }

  const [triggerGetTicketDetails, { data: complaintDetails }] =
    useLazyGetTicketDetailsQuery();

  const params = useParams();
  const selectedComplaintId = params.id;

  const handleRefresh = () => {
    if (selectedComplaintId) {
      triggerGetTicketDetails({ _id: selectedComplaintId });
    }
  };
  const detailsToDisplay: DetailsType[] = [
    category && { text: "Category", value: category },
    sub_category && { text: "Sub-category", value: sub_category },
    last_action && { text: "Last Action", value: last_action.toLowerCase() },
    // resolution && { text: "Resolution", value: resolution },
  ].filter((item): item is DetailsType => !!item);

  return detailsToDisplay?.length > 0 ? (
    <div className="flex flex-col my-32">
      {title && (
        <>
          <div className="flex justify-stretch align-center">
            <p className="txt-16 txtw-6 flex-1 clr-grey">Issue Title </p>
            <span className="flex-3 txt-14 txtw-4 clr-grey">{title}</span>
          </div>
        </>
      )}

      {short_desc && (
        <>
          <div className="flex justify-stretch align-center">
            <p className="txt-16 txtw-6 flex-1 clr-grey">Short Desc. </p>
            <span className="flex-3 txt-14 txtw-4 clr-grey">{short_desc}</span>
          </div>
        </>
      )}
      {long_desc && (
        <>
          <div className="flex justify-stretch align-center">
            <p className="txt-16 txtw-6 flex-1 clr-grey">Long Desc. </p>
            <span className="flex-3 txt-14 txtw-4 clr-grey">{long_desc}</span>
          </div>
        </>
      )}

      {description && (
        <>
          <div className="mt-24 px-12">
            <p className="txt-18 txtw-6 mb-4">Description</p>
            <p className="flex-1 txt-14 txtw-4 clr-grey">{description}</p>
          </div>
          <Divider className="mt-20" color="#D0D5DD" />
        </>
      )}

      {ticket_created_at && (
        <>
          <div className="flex justify-stretch align-center">
            <p className="txt-16 txtw-6 flex-1 clr-grey">Ticket Created:</p>
            <span className="flex-3 txt-14 txtw-4 clr-grey">
              {getDisplayDate(ticket_created_at)},
              {getDisplayTime(ticket_created_at)}
            </span>
          </div>
        </>
      )}

      {ticket_updated_at && (
        <>
          <div className="flex justify-stretch align-center">
            <p className="txt-16 txtw-6 flex-1 clr-grey">Ticket Updated:</p>
            <span className="flex-3 txt-14 txtw-4 clr-grey">
              {getDisplayDate(ticket_updated_at)},
              {getDisplayTime(ticket_updated_at)}
            </span>
          </div>
          <Divider className="mt-20" color="#D0D5DD" />
        </>
      )}

      <div className="px-12 flex flex-col gap-20 mt-24">
        {detailsToDisplay.map(({ value, text }: DetailsType) => (
          <div className="flex justify-stretch align-center" key={text}>
            <p className="txt-16 txtw-6 flex-1 clr-grey">{text}</p>
            <span className="flex-6">
              <RoleStatusBadge
                type={value}
                noColor={title === "Last Action" ? false : true}
              />
            </span>
          </div>
        ))}
      </div>
      <Divider className="mt-20" color="#D0D5DD" />

      {resolution?.action_triggered &&
        resolution?.action_triggered?.length > 0 && (
          <>
            <div className="px-12">
              <p className="txt-18 txtw-6 mb-4">Resolution</p>
              <div className="flex justify-stretch align-center">
                <p className="txt-16 txtw-6 flex-1 clr-grey">Action:</p>
                <span className="flex-3 txt-14 txtw-4 clr-grey">
                  {resolution?.action_triggered}
                </span>
              </div>
              <div className="flex justify-stretch align-center">
                <p className="txt-16 txtw-6 flex-1 clr-grey"> Short Desc. :</p>
                <span className="flex-3 txt-14 txtw-4 clr-grey">
                  {resolution?.short_desc}
                </span>
              </div>
              <div className="flex justify-stretch align-center">
                <p className="txt-16 txtw-6 flex-1 clr-grey"> Long Desc. :</p>
                <span className="flex-3 txt-14 txtw-4 clr-grey">
                  {resolution?.long_desc}
                </span>
              </div>
            </div>
            <Divider className="mt-20" color="#D0D5DD" />
          </>
        )}

      {imagesList && imagesList?.length > 0 && (
        <>
          <div className="mt-24 px-12">
            <p className="txt-16 txtw-6 mb-4 clr-grey">Images</p>
            <ImagePreview imagesList={imagesList} />
          </div>
          <Divider className="mt-20" color="#D0D5DD" />
        </>
      )}
      {/* {status === "OPEN" && ( */}
      <div>
        <p className="txt-18 txtw-6 mt-24 mb-16">Take Action</p>
        <div className="px-32 flex flex-col">
          <div className="flex">
            <p className="w-300 txt-14 txtw-6 clr-grey mr-10">Reply to issue</p>
            <Input.Wrapper
              withAsterisk
              classNames={{
                label: "txt-12 txtw-4",
              }}
              className="flex justify-center"
              required
            >
              <Textarea
                variant="filled"
                {...takeActionForm.getInputProps("description")}
                aria-multiline
                classNames={{
                  input:
                    "w-full min-w-400 max-w-512 h-150 bg-white bd-full brd-10",
                }}
              />
            </Input.Wrapper>
          </div>
          <Divider color="#D0D5DD" className="my-24" />
          <div className="flex">
            <p className="w-300 txt-14 txtw-6 clr-grey mr-10">Attach images</p>
            <div className="flex justify-center w-400">
              <Dropzone
                mediaTypes={["image/png", "image/jpeg", "image/svg+xml"]}
                media={issueImage || ""}
                setMedia={setIssueImage}
                mediaValidationText={""}
                setMediaValidationText={() => {}}
              />
            </div>
          </div>

          <div className="align-self-end mt-24 flex gap-20">
            <Button
              type="outline"
              text="Cancel"
              onClick={() => console.log("cancel")}
            />
            <Button
              type="filled-primary"
              text="Reply"
              onClick={() => handlereply()}
            />
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  ) : (
    <EmptyPage item="Issue Details" img={NoOrdersSVG} />
  );
}
