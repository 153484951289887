/* eslint-disable @typescript-eslint/no-unused-vars */
import CustomTable from "components/CustomTable/CustomTable";
import DetailsCard from "components/DetailsCard/DetailsCard";
import { Divider, Text, Timeline } from "@mantine/core";
import { ReactComponent as CheckCircle } from "assets/icons/CheckCircle.svg";
import { ReactComponent as PhonePe } from "assets/icons/Phonepe.svg";
import { ReactComponent as Visa } from "assets/icons/Visa.svg";
import "pages/OrderDetails/OrderDetails.scss";
import { Fragment, useEffect, useState } from "react";
import { getDisplayDate, getDisplayTime } from "utils/helper";
import { ecommerceColumns } from "components/OrderDetails/Columns";
import SupportTable from "components/OrderDetails/SupportTable";
import { Header } from "components/Common/Header";
import {
  useGetOrderDetailsQuery,
  // useGetOrderTrackingDetailsQuery,
} from "hooks/Admin/orderAPI";
import { useParams } from "react-router-dom";

export default function ECommerceOrderDetails() {
  const params = useParams();
  const productId = params.id;
  const [productsListing, setProductsListing] = useState([]);
  const { data: orderDetails } = useGetOrderDetailsQuery(productId);
  // const { data: trackingDetails } = useGetOrderTrackingDetailsQuery(productId)

  const {
    refund,
    settlement,
    cart,
    parentOrder,
    customerOrderId,
    customerId,
    createdAt,
    updatedAt,
    fulfillment,
    supportTickets,
    returns,
    status,
    payment,
  } = orderDetails?.data ?? {};

  let refundDetails = refund || null;

  if (refund) {
    if (Array.isArray(refund)) refundDetails = refund;
    else refundDetails = [refund];
  }

  const currentTrackingStatus = fulfillment?.status;
  const trackingDetailsArr = [
    {
      status: "Pending",
      active: currentTrackingStatus === "Pending",
      text: "Pending confirmation",
    },
    {
      status: "Packed",
      active: currentTrackingStatus === "Packed",
      text: "Order packed",
    },
    {
      status: "Assigned",
      active: currentTrackingStatus === "Agent-assigned",
      text: "Agent assigned to order",
    },
    {
      status: "Picked Up",
      active: currentTrackingStatus === "Order-picked-up",
      text: "Order picked up",
    },
    {
      status: "On the way",
      active: currentTrackingStatus === "Out for delivery",
      text: "Order out for delivery",
    },
    {
      status: "Delivered",
      active: currentTrackingStatus === "Order-delivered",
      text: "Order delivered",
    },
    // {
    //   status: "Cancelled",
    //   active: currentTrackingStatus === "Cancelled",
    //   text: "Order cancelled",
    // },
    // {
    //   status: "Cancellation pending",
    //   active: currentTrackingStatus === "Cancel-pending",
    //   text: "Order cancellation pending",
    // },
  ];

  const transactionID =
    parentOrder?.payments?.acquirerData?.upiTransactionId ||
    parentOrder?.payments?.acquirerData?.bankTransactionId ||
    parentOrder?.payments?.acquirerData?.transactionId;

  const productsListingHandler = () => {
    let newCart: any = cart?.items;
    let newReturns: any = returns;
    let products: any = [];
    newCart.forEach((cartItem: any) => {
      newReturns.forEach((returnItem: any) => {
        if (cartItem?.id === returnItem?.itemId) {
          products.push({ ...cartItem, ...returnItem });
        } else {
          products.push(cartItem);
        }
      });
    });
    console.log("FINAL PRODUCTS LISTING", products);
    setProductsListing(products);
  };

  useEffect(() => {
    // if (returns?.length) {
    //   productsListingHandler()
    // } else {
    setProductsListing(cart?.items);
    // }
  }, [cart, returns]);

  return (
    <div className="pg-container ">
      <Header />
      <main
        className="pg-main bg-white flex-1"
        style={{ height: "calc(100% - 66px)" }}
      >
        <div className="orderDetails">
          <div className="mb-30 txt-30 txtw-6">{cart?.providerName}</div>

          <div className="detailsBanner">
            <p>{customerId?.name}</p>
            <p>Order ID: {customerOrderId || "-"}</p>
            <p>
              Time Placed:{" "}
              {createdAt ? (
                <>
                  {getDisplayDate(createdAt)}, {getDisplayTime(createdAt)}
                </>
              ) : null}
            </p>
            <p>
              Time Received:{" "}
              {updatedAt ? (
                <>
                  {getDisplayDate(updatedAt)}, {getDisplayTime(updatedAt)}
                </>
              ) : null}
            </p>
          </div>

          <div className="detailsCardContainer">
            <div className="detailsCard">
              <p className="description">Total Products</p>
              <p className="subHeading">{cart?.items?.length}</p>
            </div>
            <div className="detailsCard">
              <p className="description">Total Amount</p>
              <p className="subHeading">
                <span>{settlement?.amount?.currency}&nbsp;</span>
                {settlement?.amount?.value.toFixed(2)}
              </p>
            </div>
            <div className="detailsCard">
              <p className="description">Paid By</p>
              <p className="subHeading txt-ucase">
                {settlement?.settlementInfo?.settlementType}
              </p>
            </div>
            <div className="detailsCard">
              <p className="description">Order Status</p>
              <p className="subHeading">{status}</p>
            </div>
          </div>

          <div className="productsDetailsContainer">
            <div className="detailsDescription mb-15 mt-40">Products</div>

            {productsListing?.length && (
              <CustomTable
                columns={ecommerceColumns}
                data={productsListing}
                className="productsTable mb-30"
              />
            )}
          </div>

          <div className="productDetailsCardContainer mb-30">
            <DetailsCard detailsType="Payment Details" isInnerCard={true}>
              <div className="flex justify-stretch">
                <div>
                  <Visa />
                </div>
                <div>
                  <br />
                  <p className="txt-ucase">
                    {settlement?.settlementInfo?.settlementType || "-"}
                  </p>

                  <p className="txt-ucase">
                    Bank Account No:
                    {settlement?.settlementInfo?.bankAccountNo || "-"}
                  </p>
                  <p className="txt-ucase">
                    Transaction ID:{" "}
                    {payment?.metaData?.buyer?.razorpayPayment?.acquirerData
                      ?.bankTransactionId || "0"}
                  </p>
                  <p className="txt-ucase">
                    Time Done: &nbsp;
                    {getDisplayDate(
                      payment?.metaData?.buyer?.razorpayPayment?.createdAt
                    ) +
                      " " +
                      getDisplayTime(
                        payment?.metaData?.buyer?.razorpayPayment?.createdAt
                      ) || "-"}
                  </p>
                </div>
                <div>{/* <PhonePe /> */}</div>
              </div>
            </DetailsCard>

            <DetailsCard
              // isTag={true}
              detailsType="Delivery Address"
              isInnerCard={true}
              // status={fulfillment?.status}
            >
              <div>{parentOrder?.addresses?.delivery?.name}</div>
              <div>{parentOrder?.addresses?.delivery?.building}</div>
              <div>{parentOrder?.addresses?.delivery?.locality}</div>
              <div>{parentOrder?.addresses?.delivery?.city}</div>
              <div>
                {parentOrder?.addresses?.delivery?.state} -{" "}
                {parentOrder?.addresses?.delivery?.areaCode}
              </div>
            </DetailsCard>

            <DetailsCard
              isButton={true}
              detailsType="Seller details"
              isInnerCard={true}
              buttonText="Contact Seller"
            >
              <div>{cart?.providerName}</div>
              <div>{cart?.providerAddress?.street}</div>
              <div>{cart?.providerAddress?.city?.code}</div>
              <div>
                {cart?.providerAddress?.state?.code ||
                  cart?.providerAddress?.state}{" "}
                - {cart?.providerAddress?.areaCode}
              </div>
            </DetailsCard>

            {returns?.length && (
              <>
                <DetailsCard
                  // onClickButton={window.open("tel:900300400")}
                  detailsType="Return details"
                  // isButton={true}
                  // buttonText="Contact tracking partner"
                >
                  <div>
                    <ul>
                      {returns?.map((item: any) => {
                        return (
                          <li>
                            <p>
                              <b>{item?.status}:</b>&nbsp;
                              {item?.returnReason?.reason}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </DetailsCard>
              </>
            )}

            {refundDetails && (
              <DetailsCard detailsType="Refund details" isInnerCard={false}>
                <div>
                  {refundDetails?.map((refund: any, idx: number) => (
                    <Fragment key={idx}>
                      <div className="flex justify-stretch ">
                        <p>{refund.refundType}</p>{" "}
                        <p>{refund.refundedAmount}</p>
                      </div>
                      <Divider className=" pb-2 mt-10 mb-10" color="#D0D5DD" />
                    </Fragment>
                  ))}
                  <div className="flex justify-stretch txt-18 txtw-6 ">
                    <p>Total Amount</p>{" "}
                    <p>
                      {refundDetails.reduce(
                        (acc: number, cur: any) => cur.refundedAmount + acc,
                        0
                      )}
                    </p>
                  </div>
                </div>
              </DetailsCard>
            )}

            <DetailsCard detailsType="Billing details" isInnerCard={false}>
              <div>
                {cart?.items?.map((item: any) => (
                  <Fragment key={item.name}>
                    <div className="flex justify-stretch ">
                      <p>{item.name}</p> <p>{item.price.value}</p>
                    </div>
                    <Divider className=" pb-2 mt-10 mb-10" color="#D0D5DD" />
                  </Fragment>
                ))}
                <Fragment>
                  <div className="flex justify-stretch ">
                    <p>Delivery</p>{" "}
                    <p>{cart?.additions?.delivery?.value || "N/A"}</p>
                  </div>
                  <Divider className=" pb-2 mt-10 mb-10" color="#D0D5DD" />
                </Fragment>
                <Fragment>
                  <div className="flex justify-stretch ">
                    <p>Miscellaneous</p>{" "}
                    <p>{cart?.additions?.misc?.value || "N/A"}</p>
                  </div>
                  <Divider className=" pb-2 mt-10 mb-10" color="#D0D5DD" />
                </Fragment>
                <Fragment>
                  <div className="flex justify-stretch ">
                    <p>Packaging</p>{" "}
                    <p>{cart?.additions?.packing?.value || "N/A"}</p>
                  </div>
                  <Divider className=" pb-2 mt-10 mb-10" color="#D0D5DD" />
                </Fragment>
                <Fragment>
                  <div className="flex justify-stretch ">
                    <p>Tax</p> <p>{cart?.additions?.tax?.value || "N/A"}</p>
                  </div>
                  <Divider className=" pb-2 mt-10 mb-10" color="#D0D5DD" />
                </Fragment>
                <div className="flex justify-stretch txt-18 txtw-6 ">
                  <p>Total Amount</p> <p>{cart?.price?.value}</p>
                </div>
              </div>
            </DetailsCard>

            <DetailsCard
              // onClickButton={window.open("tel:900300400")}
              detailsType="Tracking details"
              isButton={true}
              buttonText="Contact tracking partner"
            >
              {fulfillment?.status === "Cancelled" ||
              fulfillment?.status === "Cancel-pending" ? (
                <Timeline
                  active={trackingDetailsArr.findIndex(
                    (detail) => detail.active
                  )}
                  bulletSize={24}
                  lineWidth={2}
                >
                  <Timeline.Item
                    title={fulfillment?.status}
                    bullet={<CheckCircle />}
                    key={fulfillment?.status}
                    tt="capitalize"
                  >
                    <Text size="xs" mt={4}>
                      {fulfillment?.status === "Cancelled"
                        ? "Order Cancelled"
                        : "Order Cancellation Pending"}
                    </Text>
                  </Timeline.Item>
                </Timeline>
              ) : (
                <div>
                  <Timeline
                    active={trackingDetailsArr.findIndex(
                      (detail) => detail.active
                    )}
                    bulletSize={24}
                    lineWidth={2}
                  >
                    {trackingDetailsArr?.map((detail) => (
                      <Timeline.Item
                        title={detail.status}
                        bullet={<CheckCircle />}
                        key={detail.status}
                      >
                        <Text size="xs" mt={4}>
                          {detail.text}
                        </Text>
                      </Timeline.Item>
                    ))}
                  </Timeline>
                </div>
              )}
            </DetailsCard>
          </div>
          {supportTickets?.length > 0 && (
            <SupportTable supportTicketsData={supportTickets} />
          )}
        </div>
      </main>
    </div>
  );
}
