import { createContext, useContext, useState } from "react"
import { orderType } from "types/order"

const FiltersContext = createContext<any>({})

export const FiltersProvider = ({ children }: { children: any }) => {
  const [isFiltersModalVisible, setIsFiltersModalVisible] =
    useState<boolean>(false)

  return (
    <FiltersContext.Provider
      value={{
        isFiltersModalVisible,
        setIsFiltersModalVisible,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}

export const useFilters = () => useContext(FiltersContext)
