import { Card, Divider } from "@mantine/core";
import { Header } from "components/Common/Header";
import { useGetTicketsCountQuery } from "hooks/Admin/ticketAPI";
import {
  ClosedIssuesSVG,
  NoOffersSVG,
  OpenIssuesSVG,
  TotalIssuesSVG,
} from "assets/icons";
import { CardDetailType } from "types/ticket";

import { Link } from "react-router-dom";
import { EmptyPage } from "components/Common/EmptyPage";

const TicketManagement = () => {
  const { data: ticketsCount } = useGetTicketsCountQuery({});
  const { total_count, open_count, closed_count } = ticketsCount?.data || {};

  const cardDetails: CardDetailType[] | null =
    ticketsCount?.data && total_count
      ? [
          {
            title: "Total issues",
            count: total_count || 0,
            icon: TotalIssuesSVG,
            issueStatus: "all",
          },
          {
            title: "Open issues",
            count: open_count || 0,
            icon: OpenIssuesSVG,
            issueStatus: "open",
          },
          {
            title: "Closed issues",
            count: closed_count || 0,
            icon: ClosedIssuesSVG,
            issueStatus: "closed",
          },
        ]
      : null;

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main
        className="pg-main bg-white flex-1"
        style={{ position: "relative" }}
      >
        <div className="flex mr-80 justify-stretch align-center">
          <div className="section-header" style={{ marginBottom: "30px" }}>
            Ticket Management
          </div>
        </div>

        <Divider className="mx-80 pb-20" color="#D0D5DD" />
        {cardDetails ? (
          <div className="flex justify-stretch align-center mb-30 mx-120 mt-10 flex-wrap gap-20">
            {cardDetails.map((card: CardDetailType) => (
              <Card
                shadow="sm"
                radius="md"
                withBorder
                key={card.title}
                className="flex-1"
              >
                <Card.Section className="flex flex-col align-start p-24">
                  <img
                    src={card.icon}
                    className="img-contain w-48 h-48 p-10 bd-gray brd-10"
                    alt="icon"
                  />

                  <p className="pt-20 txt-14 txtw-5 clr-grey">{card.title}</p>
                  <div className="flex align-end justify-stretch w-full">
                    <p className="txt-36 txtw-6">{card.count}</p>
                    <Link
                      to={`/tickets-management/${card.issueStatus}`}
                      className="txt-14 clr-primary no-underline p-8"
                    >
                      View Details
                    </Link>
                  </div>
                </Card.Section>
              </Card>
            ))}
          </div>
        ) : (
          <EmptyPage img={NoOffersSVG} item="Tickets" />
        )}
      </main>
    </div>
  );
};

export default TicketManagement;
