import { Timeline } from "@mantine/core";
import styles from "./TicketManegement.module.scss";
import { EmptyPage } from "components/Common/EmptyPage";
import { NoOrdersSVG } from "assets/icons";
import { TimelineProps } from "types/ticket";

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const processTimeline = (timeline: any[]) => {
  return timeline.map((item) => {
    const personName = item.updated_by?.person?.name || "Unknown";
    const shortDescWithPersonName = `${item.short_desc} ${personName}`;
    return {
      ...item,
      short_desc: capitalizeFirstLetter(shortDescWithPersonName),
      timeline_action: item.complainant_action ?? item.respondent_action ?? "",
    };
  });
};

export default function IssueTimeline({ timeline }: TimelineProps) {
  const processedTimeline = processTimeline(timeline);

  return processedTimeline && processedTimeline.length > 0 ? (
    <div className="flex flex-col my-32">
      <div className="px-12 flex flex-col gap-20">
        <Timeline
          active={processedTimeline?.length}
          bulletSize={32}
          lineWidth={2}
          classNames={{ itemBullet: styles.bullet, item: styles.line }}
        >
          {processedTimeline.map(
            ({ short_desc, updated_at, timeline_action }) => (
              <Timeline.Item
                key={short_desc}
                bullet={<span className={styles["bullet-marker"]} />}
              >
                <div className="ml-13 txt-16">
                  <p className="txtw-6 clr-grey-dark">{timeline_action}</p>
                  <span>{updated_at}</span>
                  <p className="txtw-4 clr-grey">{short_desc}</p>
                </div>
              </Timeline.Item>
            )
          )}
        </Timeline>
      </div>
    </div>
  ) : (
    <EmptyPage item="Issue Timeline" img={NoOrdersSVG} />
  );
}
