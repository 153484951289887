import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "components/Common/Button"
import { EmptyPage } from "components/Common/EmptyPage"
import { Header } from "components/Common/Header"
import { SearchBarContainer } from "components/Common/SearchBarContainer"
import { AddPackagingCharge, PackagingCard } from "components/Charges"
import { useNotification } from "context"
import { getAllPackagingCharges } from "services/apis/charges/packaging"
import { NoOffersSVG } from "assets/icons/index"
import { checkAuth } from "utils/helper"
import { useUpdateTokens } from "utils/hooks"

const PackagingCharges = () => {
  const [packagingChargesList, setPackagingChargesList] = useState<Array<any>>(
    []
  )
  const [isAddPackagingModalVisible, setIsAddPackagingModalVisible] =
    useState(false)
  const [editPackagingModal, setEditPackagingModal] = useState({
    isOpen: false,
    packaging: {},
  })
  const { setText } = useNotification()
  const navigate = useNavigate()
  useUpdateTokens()

  useEffect(() => {
    ;(async () => {
      if (!isAddPackagingModalVisible && !editPackagingModal.isOpen) {
        checkAuth(
          async () => {
            const response = await getAllPackagingCharges()
            if (response.success) {
              setPackagingChargesList([...response.data])
            }
          },
          setText,
          navigate
        )
      }
    })()
  }, [isAddPackagingModalVisible, editPackagingModal.isOpen])

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main flex-1">
        <div className="pg-subheader h-40 flex justify-stretch align-center mt-30 mb-30 mx-80">
          <SearchBarContainer
            item={["Charges"]}
            itemsCount={packagingChargesList?.length}
            listingCount={packagingChargesList?.length}
          />
          <Button
            type="filled-primary"
            text="+ Add Packaging Charge"
            onClick={() => {
              setIsAddPackagingModalVisible(true)
            }}
            classNames={{
              root: "brd-10",
              label: "txt-14 txtw-5",
            }}
          />
        </div>
        {packagingChargesList.length > 0 ? (
          <>
            <section className="min-h-70 grid grid-cols-4 items-stretch gap-20 mt-5 mb-5 mx-80">
              {packagingChargesList?.map((pack) => (
                <PackagingCard
                  key={pack._id}
                  packaging={pack}
                  setEditPackagingModal={setEditPackagingModal}
                />
              ))}
            </section>
          </>
        ) : (
          <EmptyPage img={NoOffersSVG} item="Packaging charges" />
        )}
      </main>
      <AddPackagingCharge
        isAddPackagingModalVisible={isAddPackagingModalVisible}
        setIsAddPackagingModalVisible={setIsAddPackagingModalVisible}
        editPackagingModal={editPackagingModal}
        setEditPackagingModal={setEditPackagingModal}
      />
    </div>
  )
}

export default PackagingCharges
