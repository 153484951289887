import { Divider } from "@mantine/core";
import { NoOrdersSVG } from "assets/icons";
import RoleStatusBadge from "components/Common/Badge/RoleStatus";
import { EmptyPage } from "components/Common/EmptyPage";
import React, { ReactNode } from "react";
import { OrderDetailsProps } from "types/ticket";
import { capitalize } from "utils/helper";

type DetailsType = {
  text: string;
  data: DataType[] | null;
};
type DataType = {
  text: string;
  children: ReactNode;
};

const formatKeyValuePairs = (object: any) => {
  if (object)
    return Object.entries(object)
      .map(
        (item: any) =>
          item[0] &&
          item[1] && {
            text: capitalize(item[0]),
            children: item[1],
          }
      )
      .filter((item) => item);
  return null;
};

const getItems = (items: any) => {
  if (items?.length === 0) return null;
  else
    return (
      <div className="flex justofy-stretch align-center gap-20 flex-wrap">
        {items.map(
          (item: { quantity?: string; name?: string; id?: string }) => {
            if (item.quantity) {
              const itemName = item.name || item.id;
              return (
                <span key={item.id}>{`${item.quantity}x ${itemName}`}</span>
              );
            } else return null;
          }
        )}
      </div>
    );
};

const getOrderDescription = (orderDescription: any): DataType[] | null => {
  if (!orderDescription?.items || orderDescription.items.length === 0)
    return null;
  return orderDescription.items.flatMap((item: any) => [
    { text: "Item ID", children: item.id },
    { text: "Item Name", children: item.name },
    { text: "Item Description", children: item.shortDescription },
  ]);
};

export default function OrderDetails({
  customer_details,
  order_state,
  shipping_details,
  payment_status,
  order_description,
}: OrderDetailsProps) {
  const { address_line_1, address_line_2, city, pincode, phone, state } =
    shipping_details ?? {};
  const { status, fulfillments, items } = order_state ?? {};

  const shippingAddress = [address_line_1, address_line_2].filter(
    (item) => !item?.includes("undefined")
  );

  const customerDetails = formatKeyValuePairs(customer_details);
  const shippingDetails = formatKeyValuePairs({
    address: shippingAddress ? shippingAddress.join(", ") : undefined,
    city,
    pincode,
    phone,
    state,
  });

  const orderDetails = formatKeyValuePairs({
    "Order state": status && <RoleStatusBadge type={status} noColor />,
    "Order items": items?.length && getItems(items),
    "Fulfilment status": fulfillments && fulfillments[0]?.state && (
      <RoleStatusBadge type={fulfillments[0]?.state} noColor />
    ),
    "Payment status": payment_status && (
      <RoleStatusBadge type={payment_status} noColor />
    ),
  });

  const detailsToDisplay: DetailsType[] = [
    { text: "Customer Details", data: customerDetails || null },
    {
      text: "Order Description",
      data: getOrderDescription(order_description) || null,
    },
    // { text: "Shipping Details", data: shippingDetails || null },
    { text: "Order State", data: orderDetails || null },
  ];

  return customer_details ? (
    <div className="flex flex-col my-32">
      <div className="px-12 flex flex-col gap-20">
        {detailsToDisplay.map(
          ({ text, data }: DetailsType, id: number) =>
            data &&
            data.length > 0 && (
              <React.Fragment key={text}>
                <p className="txt-18 txtw-6" key={text}>
                  {text}
                </p>
                {data.map(({ text, children }: DataType) => (
                  <React.Fragment key={text}>
                    <div
                      className="flex justify-stretch align-center"
                      key={text}
                    >
                      <p className="txt-16 txtw-6 flex-1 clr-grey">{text}</p>
                      <span className="flex-3 txt-14 txtw-4 clr-grey">
                        {children}
                      </span>
                    </div>
                  </React.Fragment>
                ))}
                {id !== detailsToDisplay.length - 1 && (
                  <Divider color="#D0D5DD" />
                )}
              </React.Fragment>
            )
        )}
      </div>
    </div>
  ) : (
    <EmptyPage item="Order Details" img={NoOrdersSVG} />
  );
}
