import { Select } from "@mantine/core"
import { AdminTypeTypes } from "types/admin"
import { DownArrowSVG } from "assets/icons"
import { useAdminTypeContext } from "context/adminType"

export default function AdminTypeDropdown() {
  const { adminType, storeAdminType } = useAdminTypeContext()

  return (
    <Select
      variant="default"
      value={adminType}
      onChange={(val: string) => storeAdminType(val as AdminTypeTypes)}
      data={[
        { value: "e-commerce-b2c", label: "E-Commerce (B2C)" },
        { value: "e-commerce-b2b", label: "E-Commerce (B2B)" },
        { value: "mobility", label: "Mobility" },
      ]}
      rightSection={<DownArrowSVG />}
      classNames={{
        root: "w-fit-content",
        input: "bg-white w-fit-content",
      }}
    />
  )
}
