import { useEffect, useState } from "react"
import { Accordion, Checkbox, Radio, SegmentedControl } from "@mantine/core"
import {
  DateRangePicker,
  DateRangePickerValue,
  TimeInput,
} from "@mantine/dates"
import { Button } from "components/Common/Button"
import { Modal } from "components/Common/Modal"

import {
  DELIVERY_MODES,
  INITIAL_ORDERS_FILTERS,
  INITIAL_SORT_BY,
  MODES_OF_PAYMENTS,
  SORT_BY,
} from "constants/index"
import {
  isLocationOutletInFilter,
  resetOrdersFilter,
  updateBrandsFilter,
  updateDeliveryModesFilter,
  updateIssuesStatusFilter,
  updateLocationOutletFilter,
  updateModesOfPaymentFilter,
} from "utils/helper"
import { useSortBy } from "context"
import { constant } from "./constant"
import { getStorageItem, setStorageItem } from "services/storage"

const SortBy = ({
  isSortByModalVisible,
  type,
  onSortChange,
  setSortCount,
}: {
  isSortByModalVisible: boolean
  type: string
  onSortChange: (val: string) => void
  setSortCount: any
}) => {
  const [currentFilterSelected, setCurrentFilterSelected] = useState("")
  const [cityWiseOutlets, setCityWiseOutlets] = useState<any>({})
  const [allBrands, setAllBrands] = useState([])
  const [allModesOfPayment, setAllModesOfPayments] = useState(MODES_OF_PAYMENTS)
  const [deliveryModes, setDeliveryModes] = useState(DELIVERY_MODES)
  const [ordersFilters, setOrdersFilters] = useState<any>(INITIAL_SORT_BY)
  const { setIsSortByModalVisible } = useSortBy()

  console.log(type, "type")
  useEffect(() => {
    if (!isSortByModalVisible) {
      updateOrdersFilter("reset")
    }
  }, [isSortByModalVisible])

  useEffect(() => {
    if (type) {
      let data = getStorageItem("sortBy")

      if (data && data[type]) {
        console.log(data[type], "data")

        const defaultSelected = Object.keys(data[type])
          .toString()
          .concat("-", Object.values(data[type]).toString())
        console.log(defaultSelected)
        setOrdersFilters(data[type])
        setCurrentFilterSelected(defaultSelected)
      }
    }
  }, [type])
  console.log(currentFilterSelected, "fill")

  function updateOrdersFilter(val: any) {
    console.log(val, "fill")

    const fil = val.split("-")
    setCurrentFilterSelected(val)
    let newOrdersFilters = {}
    switch (fil[0]) {
      case "title":
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "title"
        )

        break
      case "updatedAt":
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "updatedAt"
        )

        break
      case "createdAt":
        console.log("fil[1]in")
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "createdAt"
        )
        console.log(newOrdersFilters, "orderFile")
        break
      case "totalAmount":
        console.log(fil[1])
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "totalAmount"
        )
        break
      case "totalPrice":
        console.log(fil[1])
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "totalPrice"
        )
        break

      case "categoryName":
        console.log(fil[1])
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "categoryName"
        )
        break
      case "visible":
        console.log(fil[1])
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "visible"
        )
        break

      case "reset":
        console.log(newOrdersFilters, "ord")
        newOrdersFilters = {}
        // newOrdersFilters = resetOrdersFilter(newOrdersFilters)
        break
      default:
        break
    }
    console.log(newOrdersFilters, "nerOr")
    if (newOrdersFilters) {
      setSortCount(Object.keys(newOrdersFilters)?.length)
    } else {
      setSortCount(0)
    }
    setOrdersFilters({ ...newOrdersFilters })
  }

  function isValueInFilter(filter: string) {
    console.log(filter, "filinsort")
    const filters = filter.split("-")
    switch (filters[0]) {
      case "title":
        return `title-${ordersFilters["title"]?.toString()}`
      case "updatedAt":
        console.log(ordersFilters["updatedAt"]?.toString(), "update")
        return `updatedAt-${ordersFilters["updatedAt"]?.toString()}`
      case "createdAt":
        console.log(ordersFilters["createdAt"]?.toString(), "filters")
        return `createdAt-${ordersFilters["createdAt"]?.toString()}`
      case "totalAmount":
        console.log(ordersFilters["totalAmount"]?.toString(), "amount")
        return `totalAmount-${ordersFilters["totalAmount"]?.toString()}`
      case "totalPrice":
        console.log(ordersFilters["totalPrice"]?.toString(), "amount")
        return `totalPrice-${ordersFilters["totalPrice"]?.toString()}`
      case "categoryName":
        return `categoryName-${ordersFilters["categoryName"]?.toString()}`
      case "visible":
        return `visible-${ordersFilters["visible"]?.toString()}`
      default:
        break
    }
  }

  return (
    <Modal
      title="Sort By"
      opened={isSortByModalVisible}
      onClose={() => setIsSortByModalVisible(false)}
      size={"md"}
    >
      <div className="flex align-start bd-btm pt-10 pb-10">
        <div className="flex justify-stretch  mb-15">
          <Radio.Group
            withAsterisk
            classNames={{
              root: "flex flex-col gcol-start-1 gcol-end-3 ",
              label: "txt-12 txtw-4 clr-grey txt-ucase",
            }}
            value={isValueInFilter(currentFilterSelected)}
            onChange={(e) => {
              console.log(e, "eve")

              updateOrdersFilter(e)
            }}
          >
            <div className="flex flex-col">
              {constant[type]?.map((status: any) => (
                <Radio
                  key={Math.random()}
                  value={status.value}
                  label={status.label}
                  className="mb-10"
                  classNames={{
                    label: "txt-12 txtw-4 clr-grey txt-cap",
                  }}
                />
              ))}
            </div>
          </Radio.Group>
        </div>
      </div>
      <div className="flex justify-stretch align-center pt-10">
        <Button
          type="default"
          text="Reset"
          onClick={() => {
            updateOrdersFilter("reset")
            let obj: any = ordersFilters
            setStorageItem("sortBy", obj)
            // let data:string=generateUrlSearchParams(obj[`${type}`])
            onSortChange(obj[type])
            setIsSortByModalVisible(false)
          }}
        />
        <Button
          type="filled-primary"
          text="Apply"
          onClick={() => {
            let obj: any = getStorageItem("sortBy") || {}
            obj[type] = ordersFilters
            console.log(ordersFilters, "fil")
            setStorageItem("sortBy", obj)
            // let data:string=generateUrlSearchParams(obj[`${type}`])
            onSortChange(obj[type])
            setIsSortByModalVisible(false)
          }}
        />
      </div>
    </Modal>
  )
}

export default SortBy
