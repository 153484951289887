import { Divider } from "@mantine/core"
import { Header } from "components/Common/Header"
import { SearchBarContainer } from "components/Common/SearchBarContainer"
import {
  useGetCustomerListQuery,
  useLazyDownloadCustomerListQuery,
} from "hooks/Admin/customerAPI"
import { CustomerAPIOptionsType } from "types/customerManagement"
import CustomerTable from "./CustomerTable"
import { useAdminTypeContext } from "context/adminType"
import { ecommerceColumns } from "./Columns"
import { useState } from "react"
import OptionsBarContainer from "components/Common/OptionsBarContainer/OptionsBarContainer"
import { downloadCsv } from "services/apis/admin/media"

function ECommerceCustomerManagement() {
  const [customersAPIOptions, setCustomerAPIOptions] =
    useState<CustomerAPIOptionsType>({
      page: 1,
      size: "10",
      search: "",
    })

  const onPageChange = (value: number) =>
    setCustomerAPIOptions((prev) => ({ ...prev, page: value }))

  const onRecordsSizeChange = (value: string) =>
    setCustomerAPIOptions((prev) => ({ ...prev, size: value }))

  const { data: customersList } = useGetCustomerListQuery({
    page: customersAPIOptions?.page,
    size: customersAPIOptions?.size,
    search: customersAPIOptions?.search,
  })

  const { adminType } = useAdminTypeContext()

  const onSearchChange = (value: string) => {
    setCustomerAPIOptions((prev) => ({
      ...prev,
      search: value,
    }))
  }
  const handleDownloadCSV = async () => {
    downloadCsv(
      `/customers/export?isBusiness=${
        adminType === "e-commerce-b2b" ? true : false
      }`,
      `customers_${adminType === "e-commerce-b2b" ? "b2b" : "b2c"}.csv`
    )
  }

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main bg-white flex-1">
        <div className="section-header">Customer Management</div>
        <div className="pg-subheader h-40 flex justify-stretch align-center mb-30 mx-80">
          <SearchBarContainer
            item={["Customer", "Customers"]}
            itemsCount={customersList?.data?.totalCount}
            listingCount={customersList?.data?.count}
          />
        </div>
        <Divider className="mx-80 pb-20" color="#D0D5DD" />
        <div className="mx-80 pb-20">
          <OptionsBarContainer
            placeholder="Search for customers"
            selectedTab="customer"
            onSearchChange={onSearchChange}
            isSort={false}
            isFilter={false}
            buttonText={"Download CSV"}
            onButtonClick={() => handleDownloadCSV()}
          />
        </div>
        <CustomerTable
          columns={ecommerceColumns}
          adminType={adminType}
          tableData={customersList?.data?.details}
          customersAPIOptions={customersAPIOptions}
          onPageChange={onPageChange}
          onRecordsSizeChange={onRecordsSizeChange}
          totalEntries={customersList?.data?.totalCount}
        />
      </main>
    </div>
  )
}

export default ECommerceCustomerManagement
